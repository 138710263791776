import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import gql from 'graphql-tag';
import qs from 'qs';
import Slide from '@mui/material/Slide';

import { Button, Spinner } from '../lib/components';
import { withAuthState } from '../lib/auth';
import { IMG } from '../lib/imgLib';
import __, { setLanguage, getLanguage } from '../lib/gettext';
import UserAuth from '../lib/userAuth';
import './css/header.css';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #fff',
        padding: '10px 26px 10px 12px',
        '&:focus': {
            borderRadius: 4,
            borderColor: '#fff',
        },
    },
}));

const LanguageSelector = () => {
    const [currentLang, setLang] = useState(getLanguage());

    const handleChange = (event: SelectChangeEvent) => {
        let language = event.target.value as string;
        if (language === currentLang)
            return;
        setLanguage(language);
        setLang(event.target.value as string);
        window.location.reload();
    };

    return (
        <div className='relative my-auto text-center w-24 h-12 p-0 ' >
            <Select className='h-10 text-sm mt-1 foucus:outline-none'
                sx={{ border: '0px solid #000', }}
                value={currentLang}
                label='lang'
                onChange={handleChange}
                input={<BootstrapInput />}>
                <MenuItem value={'zh'}>繁</MenuItem>
                <MenuItem value={'en'}>EN</MenuItem>
            </Select>
        </div >
    );
};

const Header = () => {
    const [showMenu, setMenu] = useState(false);
    const [auth, setAuth] = useState(false);
    const GET_HEADER_PROFLE = gql`
        query GetHeaderProfile {
            me {
                id
                avatarUrl
                email
                displayName
            }
            auth:getFGUserAuth
        }`;
    const { loading, data } = useQuery(GET_HEADER_PROFLE, {
        fetchPolicy: 'network-only',
        onCompleted: ((data: any) => {
            if (data.auth && data.auth.length > 0)
                setAuth(true);
        })
    });
    const url = useLocation().pathname;

    if (loading) return <Spinner />
    const user = data && data.me && data.me.id > 0 ? data.me : undefined;
    const logOut = () => {
        UserAuth.signOut();
        window.location.reload();
    };


    const HEADER_BLACKLIST = ['/apps/']
    let hideHeader = false;
    HEADER_BLACKLIST.forEach((blackList) => {
        blackList = blackList.toLowerCase();
        if (url.toLowerCase().includes(blackList)) {
            hideHeader = true;
        }
    });

    if (hideHeader) return null;


    const toggleMenuEventListner = () => {
        setMenu(false);
        document.body.removeEventListener('click', toggleMenuEventListner);
    };

    const toggleHidden = (e: MouseEvent) => {
        e.stopPropagation();
        if (showMenu) {
            setMenu(false);
        } else {
            setMenu(true);
        }
        if (showMenu) {
            document.body.removeEventListener('click', toggleMenuEventListner);
        } else {
            document.body.addEventListener('click', toggleMenuEventListner);
        }
    };

    return (
        <div className={'fixed left-0 top-0 w-full flex text-black z-90 bg-white flex'} style={{ boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.25)' }}>
            <div className='relative flex section-app lg:max-w-pc h-[50px] px-4'>
                <div className='w-1/2 flex'>
                    <Link to='/' className='ml-10 my-auto'>
                        <IMG src='FANSIGO' className='w-32 ' />
                    </Link>
                </div>
                <div className='w-1/2 flex justify-end'>
                        <LanguageSelector />
                        {user ? <>
                        <Link className='my-auto mr-2' to='https://sweet-angle-e85.notion.site/FAQ-45d76bebcf1c4c02888dbc457ab98b3d' target='_blank'>
                            <IMG src='Question' />
                        </Link>
                        <IMG onClick={toggleHidden} src='Gear' className='cursor-pointer my-auto' />
                        {showMenu ?
                            <div className='absolute w-44 min-h-20 pr-4 right-0 mt-11 overflow-hidden'>
                                <Slide direction='down' in={showMenu} mountOnEnter unmountOnExit>
                                    <div className='relative bg-black z-50 bg-white p-3 rounded-[8px] border border-[#e7e7e7]' style={{ boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.14)' }}>
                                        <Link to='/collection' className='hidden lg:block w-full mb-2'>
                                            <p className='text-center text-sm'>{__('FOOTER_Collection')}</p>
                                        </Link>
                                        <Link to='/UserProfile' className='w-full'>
                                            <p className='text-center text-sm'>{__('FGU_Edit_Profile')}</p>
                                        </Link>

                                        {auth ? <>
                                            <div className='w-full h-px my-2 bg-[#efefef]' />
                                            <Link to='/dashboard' className='w-full'>
                                                <p className='text-center text-sm'>工作人員後台</p>
                                            </Link> </> : ''}
                                        <div className='w-full h-px my-2 bg-[#efefef]' />
                                        <button className='relative z-50 w-full cursor-pointer ' onClick={logOut} >
                                            <p className='w-full text-center text-sm'>{__('Logout')}</p>
                                        </button>
                                    </div>
                                </Slide>
                            </div> : ''}
                        </>
                            : <>
                            <a href={'/login?' + qs.stringify({ n: window.location.pathname })} className='my-auto'>
                                <Button className=' text-white my-auto px-3 h-[21px] bg-[#fb7f65] rounded-[31px] flex justify-center'>
                                    <p className='text-sm font-semibold my-auto'>登入</p>
                                </Button>
                            </a>
                        </>}
                </div>
            </div>
        </div>
    );
};


export default withAuthState(Header);