import React, { FC, CSSProperties, forwardRef, ReactElement, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast, ToastContent } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import ReactSpinkit, { SpinnerProps } from 'react-spinkit';
import pipe from 'ramda/es/pipe';
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage/canvasUtlis'
import Slide from '@mui/material/Slide';

import __, { getLanguage } from './gettext';
import spin from '../components/img/loading-spin.gif';
import { IMG } from './imgLib';


export const Spinner = ({ name, color, className, style }: { name?: SpinnerProps['name'], color?: string, className?: string, style?: React.CSSProperties }) => {
    return <ReactSpinkit name={name ? name : 'ball-beat'} color={color ? color : 'black'}
        className={className ? className : ''} fadeIn='none' style={style ? style : {}} />;
};

export const Loading = () => {
    return (
        <h1 className='container section h-screen flex-grow text-center mt-4'>
            Loading...
            <Spinner className='mx-auto' />
        </h1>
    )
};

export const error = pipe(
    (err: Error | ToastContent) => {
        return err instanceof Error ? err.message : err;
    },
    (msg: ToastContent) => {
        // Strip "graphql error" string if present
        if (typeof msg === 'string') msg = msg.replace(/^GraphQL error:\s+?/i, '');
        return msg;
    },
    (msg: ToastContent) =>
        toast.error(msg, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        }),
);

export const success = pipe(__, (msg: ToastContent) =>     
    toast.success(msg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    })
);

interface SEOProps {
    title: string;
    url?: string;
    description?: string;
    children: any;
}

interface BtnProps {
    appearance?: 'default' | 'blue' | 'black' | 'rainbow';
    disabled?: boolean;
    className?: string;
    shadow?: boolean;
    [key: string]: any;
}

export const Button = ({
    appearance = 'default',
    disabled = false,
    shadow = false,
    className,
    ...props
}: BtnProps) => {
    const cs = 'focus:outline-none btn' +
        (shadow ? 'btn-shadow' : '') +
        (disabled ? 'btn-disabled' : '') +
        (appearance === 'blue' ? 'btn-blue' : '') +
        (appearance === 'black' ? 'btn-black' : '') +
        (appearance === 'rainbow' ? 'btn-rainbow' : '');
    return <button className={cs + className} {...props} />;
};

export const SEO = (props: SEOProps) => {
    const { title, description, url, children } = props;
    return (
        <Helmet title={title} >
            <meta property='og:title' content={title} />
            {url ? <meta property='og:url' content={url} /> : ''}
            {description && [
                <meta key='name' name='description' content={description} />,
                <meta key='prop' property='og:description' content={description} />,
            ]}
            {children}
        </Helmet>
    );
};

export const ScrollToTopOnce = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);
    return null;
};

export function GetDefaultAvatar(id?: number) {
    if (!id) id = 0;
    return 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/avatar/1.png'
}

export function GetWeekDay(dayNum: number){
    const day = ['週日', '週一', '週二', '週三', '週四', '週五', '週六']
    return day[dayNum];
}

export const genderList = [{id: 0, "zh": "男性", "en": "Male"}, {id: 1, "zh": "女性", "en": "Female"}, {id:2, "zh": "其他", "en": "Others"}, {id:3, "zh": "不透露", "en": "prefer not to say"}]

export const EasyCrop = ({ callback, lang }: { callback: any, lang?: string }) => {
    const inputRef = useRef(null);
    const [image, setImage] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const onCropComplete = (croppedAreaPercentage:any, croppedAreaPixels:any) => {      
        setCroppedArea(croppedAreaPixels);
    };

    const onSelectFile = (event:any) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.addEventListener("load", () => {
            //@ts-ignore
            setImage(reader.result);
            });
        }
    };
  
    async function getImg(){
      let cropImg = await getCroppedImg(image, croppedArea);
      callback(cropImg)
    //   UploadFile(cropImg);
      closeCrop()
    }

    function closeCrop(){
        setImage(null)
        //inputRef.current = null;
    }

    const handleClick = (event:any) => {
        //@ts-ignore
        inputRef.current.click();
      };
  
    return (
        <div className='reltaive w-full h-full'>
            <div className=' container-buttons'>
            <input
					type='file'
					accept='image/*'
					ref={inputRef}
					onChange={onSelectFile}
					style={{ display: "none" }}
				/>
                <button onClick={handleClick} className='mt-1 w-28 rounded-[31px] border border-[#767676] h-7 px-2 font-medium text-sm text-[#767676]'>
                    {lang && lang === 'en' ? 'Upload Avatar' : '從照片上傳'}</button>
                </div>
     
        {image ? 
        <div className='container absolute-center'>
			<div className='container-cropper'>
                {image ?
                (
                    <>
                        <div className='cropper relative'>
                            <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                            />
                                        <button onClick={getImg} className='horizon-center bottom-0 mt-1 w-20 rounded-[31px] border border-white h-12 px-2 font-medium text-xl text-white'>
                                            {lang && lang === 'en' ? 'Crop' : '選取'}</button>
                        </div>
                    </>
                ) : null
                }
            </div>
                
                    <div className='container-buttons text-center'>	
                    </div>
        </div>:''}
        </div>
    );
  }

export const TabLink = forwardRef<any, any>((props, ref) => (
    <NavLink
        ref={ref}
        to={props.to}
        className={({ isActive }) => {
            const tabClass = ['tab-nav', props.className];
            if (isActive) tabClass.push('tab-nav-active');
            return tabClass.join(" ");
        }} end>
        {props.children}
    </NavLink>
));

//無條件進位
export const roundUp = ( num:any, decimal:any ) => { 
    return Math.ceil( ( num.toFixed(5) ) * Math.pow( 10, decimal ) ) / Math.pow( 10, decimal ); 
}
    

//無條件捨去
export const roundDown = ( num:any, decimal:any ) => { 
    return Math.floor( ( num + Number.EPSILON ) * Math.pow( 10, decimal ) ) / Math.pow( 10, decimal ); 
}


export function ScrollToAnchor() {
    const location = useLocation();
    const lastHash = useRef('');
  
    // listen to location change using useEffect with location as dependency
    // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
    useEffect(() => {
      if (location.hash) {
        lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
      }
  
      if (lastHash.current && document.getElementById(lastHash.current)) {
        setTimeout(() => {
          document
            .getElementById(lastHash.current)
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          lastHash.current = '';
        }, 100);
      }
    }, [location]);
  
    return null;
  }

export const SubMenu = ({ title, url }: { title: string, url: string }) => {
    const [isFixed, setFixed] = useState(false);
    useEffect(() => {
        function chkTop() {
            let currY = window.scrollY;
            if (currY < 50 && isFixed) setFixed(false)
            if (currY > 50 && !isFixed) setFixed(true);
        }
        window.addEventListener("scroll", chkTop);
        return () => {
            window.removeEventListener("scroll", chkTop);
        };
    }, [isFixed, setFixed])

    return (
        <div className={(isFixed ? 'fixed z-999 top-0 ' : '') + ' section-md md:section-app h-12 flex border border-[#ededed] bg-white text-black px-4 justify-center'}
            style={{ boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.15)' }} >
            <Link to={url} className='my-auto'>
                <IMG src='ArrowLeftBa' className='cursor-pointer w-[26px] h-[26px]' />
            </Link>
            <p className='lg:w-3/5 w-full text-center text-lg my-auto font-semibold truncate'>{title}</p>
        </div>
    )
}

export const ParseTextURL = (str: string) => {
    if (typeof (str) !== 'string') return;
    const regex = /\[(.*?)\]\((.*?)\)/g; // Regex to match text within square brackets and parentheses
    let lastIndex = 0;
    const elements: React.ReactNode[] = [];
    let match;    
    while ((match = regex.exec(str)) !== null) {

        // Push text before match
        elements.push(str.substring(lastIndex, match.index));

        // Push company name within span
        elements.push(
            <span key={match.index} className='text-[#00b9c4] underline'>
                {/* match[1] is the company name, match[2] is the URL */}
                <Link to={match[2]} target='_blank'>{match[1]}</Link>
            </span>
        );

        lastIndex = regex.lastIndex;
    }

    // Push remaining text after the last match
    elements.push(str.substring(lastIndex));

    return elements;
};

export const DescParser = ({ info }: any) => {
    if (typeof (info) === 'string') return <p className='mt-2 whitespace-pre-wrap'>{info}</p>
    if (!info['type']) return <div />
    let lang = getLanguage();
    switch (info['type']) {
        case 'text':
            let content = info['content'];
            if (info['content_' + lang]) content = info['content_' + lang];
            return <p className={'mt-2 whitespace-pre-wrap ' + info['className']}>{ParseTextURL(content)}</p>
        case 'img':
            return <img className={'mt-2 ' + info['className']} src={info['url'] + ''} alt='img' />
        case 'video':
            return <div className='relative w-full h-0 pb-[56.25%]'>
                <iframe className='absolute w-full h-full' src={'https://www.youtube.com/embed/' + info['content']} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowFullScreen />
            </div>            
    }
    return <div />
}

export const SystemNotice = ({ title, content, url }: { title: string, content: ReactElement, url: string }) => {
    const [showNoticet, setNotice] = useState(true);
    return (
        <div className='fixed bottom-0 left-0 w-full flex justify-center ' style={{ zIndex: 900 }}>
            <Slide direction='up' in={showNoticet} mountOnEnter unmountOnExit>
                <div className='absolute w-5/6 max-w-[385px] mx-auto h-28 -top-[190px] px-4'>
                    <div className='w-full h-24 bg-black text-white rounded-xl p-4 flex'>
                        <div className='h-full aspect-square rounded-xl mr-3 flex'
                            style={{ backgroundImage: 'linear-gradient(139deg, #00b7c2 -3%, #0ab4bd -1%, #5ea19e 13%, #a09285 25%, #d08773 35%, #ed8068 42%, #f97e64 47%), linear-gradient(to bottom, #000, #000)' }} >
                            <p className='text-xl font-extrabold text-center leading-none my-auto'>
                                {title}
                            </p>
                        </div>
                        <div className='w-full'>
                            {content}
                            <div className='w-full flex justify-end'>
                                <button className='px-2 h-7 z-90 mr-6' onClick={() => { setNotice(false) }}>
                                    <p className='w-full text-center text-xs my-auto'>{__('Not_Now')}</p></button>
                                <Link to={url}>
                                    <button className=' rounded-3xl w-24 h-7 bg-[#ffc76e] z-90' >
                                        <p className='w-full text-center text-gray-48 font-medium my-auto'>{__('SYS_CHK_NOW')}</p></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
        </div>
    )
}

interface LazyImageProps {
    src: string;
    className?: string;
    style?: CSSProperties;
    alt?: string;
    onClick?: () => void;
}

export const LazyImg: FC<LazyImageProps> = ({ src, className, style, alt, onClick }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {        
        const img = new Image();
        img.src = src;
        img.onload = () => {            
            setImageSrc(src);
            setLoading(false);
        };
        img.onerror = () => {
            setError(true);
            setLoading(false);
        };
    }, [src]);

    if (loading) {
        return (
            <div className={className} style={style}>
                <div className='w-full h-full flex'>
                    <img className='w-5 h-5 m-auto' src={spin} alt="Loading..." />
                </div>
            </div>
        );
    }

    if (error) {
        console.warn(`Error loading image ${src}`)
        return null;
    }

    return (
        <img src={imageSrc!} className={className} style={style} alt={alt} onClick={onClick} />
    );
};

export const DownloadCSV = ({ data, fileName, className } : { data:any, fileName:any, className?:string}) => {
    const convertToCSV = (objArray:any) => {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
  
      for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
          if (line !== '') line += ',';
  
          line += array[i][index];
        }
        str += line + '\r\n';
      }
      return str;
    };
  
    const downloadCSV = () => {
      const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = csvURL;
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    return (
      <button className={`rounded-lg p-2 text-black ${className}`} style={{borderWidth: 1, borderColor:'#848484'}} onClick={downloadCSV}>下載 CSV</button>
    );
  }

interface ImgUploaderProps {
    type: string;
    imgName: string;
    MAX_SIZE?: number;
    callBack: (url: string) => void;
}

export const ImgUploader = ({ type = 'event', imgName, MAX_SIZE = 1024 * 1024 * 2, callBack }: ImgUploaderProps) => {
    const UPLOAD_FILE = gql`
        mutation UploadAvatar($base64Image: String!, $imageName: String, $type: String) {
            file: uploadAvatar(base64Image: $base64Image, imageName: $imageName, type: $type) {
                url
            }
        }
    `;
    const [uploadFile] = useMutation(UPLOAD_FILE);
    const inputFile = useRef<HTMLInputElement | null>(null);

    const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file.size < MAX_SIZE) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (reader.result) {
                        UploadFile(reader.result.toString(), imgName);
                    }
                };
            } else {
                error('檔案過大，請裁切後再上傳');
            }
        }
    };

    const UploadFile = (base64: string, imageName: string) => {
        if (base64) {
            uploadFile({ variables: { base64Image: base64, imageName, type } })
                .then(({ data }: any) => {
                    if (data?.file?.url) {
                        callBack(data.file.url);
                    }
                })
                .catch((err) => {
                    console.warn(err.message);
                });
        }
    };

    const handleClick = () => {
        inputFile.current?.click();
    };

    return (
        <button onClick={handleClick} className='h-10 border border-gray-8d text-gray-8d rounded-4xl flex py-2 px-4 mr-3'>
            <input type='file' onChange={onSelectFile} ref={inputFile} accept='image/jpg, image/jpeg' style={{ display: 'none' }} />
            <p className='text-sm m-auto'>＋上傳圖片</p>
        </button>
    );
};