import { useEffect, useRef, Suspense, lazy } from 'react';
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  Outlet,
} from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LogInPage } from '../lib/auth';
import { SEO, Spinner } from '../lib/components'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import __ from '../lib/gettext';

import ErrorBoundary from './ErrorBoundary';
import Header from './Header';
import Home from './Home';
import Footer from './Footer';
import Service from './service';
import Privacy from './privacy';

const router = createBrowserRouter([
  { path: "/*", Component: Root },
]);

const Collection = lazy(() => import('./collection'));
const Dashboard = lazy(() => import('./dashboard'));
const FireballMaster = lazy(() => import('./events/FireballMaster'));
const FireEX2024NA = lazy(() => import('./events/FireEX2024NA'));
const NeonOasis = lazy(() => import('./events/NeonOasis'));
const NftDrops = lazy(() => import('./NftDrops'));
const Tickets = lazy(() => import('./tickets'));
const UserProfile = lazy(() => import('./UserProfile'));
const Creator = lazy(() => import('./dashboard/creator'));
const Apps = lazy(() => import('./apps'));
const Coporation = lazy(() => import('./corporation'));

const App = () => {
  return <RouterProvider router={router} />;
}

function Root() {
  // let ServerOnMaintenance = false;
  // const curr = Date.now();
  // const MaintenStart = new Date('2024-08-23 18:00+08').getTime();
  // const MaintenEnd = new Date('2024-08-23 23:00+08').getTime();
  // if (curr > MaintenStart && curr < MaintenEnd) ServerOnMaintenance = true;
  // if (ServerOnMaintenance)
  //   return (
  //     <div className='relative w-full h-screen p-6 overflow-hidden'>
  //       <p className='mt-16 w-full text-black text-center font-bold text-4xl'>Server under maintenance</p>
  //       <div className='absolute w-full bottom-0 mb-12'>
  //         <Footer />
  //       </div>
  //     </div>
  //   )
  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_API_KEY + ''}>
        <ToastContainer />
        <Routes >
          <Route path='/dashboard/creator/:eventId' element={<Suspense fallback={<FallBack />}>
            <Creator /> </Suspense>} />
          <Route path='/NftDrops/*' element={<Suspense fallback={<FallBack />}>
            <NftDrops /> </Suspense>} />
          <Route path='/FireEX2024NA/*' element={<Suspense fallback={<FallBack />}>
            <FireEX2024NA /> </Suspense>} />
          <Route path='/FireballMaster2023/*' element={<Suspense fallback={<FallBack />}>
            <FireballMaster /> </Suspense>} />
          <Route path='/NeonOasis2024/*' element={<Suspense fallback={<FallBack />}>
            <NeonOasis /> </Suspense>} />
          <Route path='/Login/*' element={<LogInPage />} />
          <Route path='/service' element={<Service />} />
          <Route path='/privacy' element={<Privacy />} />    
          <Route element={<Main size='medium' bgColor=' ' bgImg='ticket' />} >
            <Route path='/apps/*' element={<Suspense fallback={<FallBack />}>
              <Apps /> </Suspense>} />
          </Route>
          <Route element={<Main size='medium' />} >
            <Route path='/collection/*' element={<Suspense fallback={<FallBack />}>
              <Collection /> </Suspense>} />  
            <Route path='/dashboard/*' element={<Suspense fallback={<FallBack />}>
              <Dashboard /> </Suspense>} />          
            <Route path='/tickets/*' element={<Suspense fallback={<FallBack />}>
              <Tickets /> </Suspense>} /> 
            <Route path='/userProfile/*' element={<Suspense fallback={<FallBack />}>
              <UserProfile /> </Suspense>} />         
          </Route>
          <Route path='/' element={<Main size='full' />} >
            <Route path='/corporation/*' element={<Suspense fallback={<FallBack />}>
              <Coporation /> </Suspense>} />         
            <Route path='/' element={<Home />} />
            <Route path='*' element={<Home />} />
          </Route>   
        </Routes>
      </GoogleOAuthProvider>
    </ErrorBoundary >
  );
}

export const FallBack = () => {
  return <div className='relative min-h-2/3-screen w-full'>
    <div className='absolute-center'>
      <Spinner name="folding-cube" color="fuchsia" />
    </div>
  </div>
}

const Main = ({ size, bgColor, bgImg }: { size: string, bgColor?: string, bgImg?: string }) => {
  let HIDDEN_FOOTER = false;
  const FOOTER_HIDDEN_LIST = ['/BSCxGrimes'];
  const url = window.location.href.toLowerCase();
  FOOTER_HIDDEN_LIST.forEach((list) => {
    list = list.toLowerCase();
    if (url.includes(list)) HIDDEN_FOOTER = true;
  });

  function ScrollCheck() {
    if (window.scrollChk) {
      window.scrollChk();
    }
  }

  let sectionSize = 'lg:max-w-mdpc';
  if (size === 'large') sectionSize = 'lg:max-w-pc'
  if (size === 'full') sectionSize = 'lg:max-w-full'

  useEffect(() => {
    function Scroll2Top() {
      if (!containerRef.current) return;
      const containerElement = containerRef.current;
      containerElement.scrollTop = 0;
    }
    window.scroll2Top = Scroll2Top;
  }, [])

  const containerRef = useRef<HTMLDivElement>(null);

  let bgImgUrl = 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/fansi-go-body-bg.jpg';
  switch (bgImg) {
    case 'ticket':
      bgImgUrl = 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/background-ticket-claim.jpg';
      break;
  }

  return (
    <div className={'h-screen w-screen overflow-hidden bg-fixed bg-cover bg-center'} style={{ backgroundImage: `url(${bgImgUrl})` }}>
      <div ref={containerRef} onScroll={ScrollCheck} className={'flex flex-col h-screen overflow-y-auto Roboto text-black'}
        style={{ scrollbarWidth: 'thin', scrollbarColor: 'rgb(76, 76, 76,0.5) #ededed' }}>
        <SEO
          title={__('Home_Desc')}
          url={window.location.href}
          description={__('Web_Default_Desc')}>
          <meta property='og:image' content={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Tickets/Banner/FANSI-GO-OG.jpg'} />
          <meta property='og:url' content={window.location.href} />
        </SEO>
        <Header />
        <div className={`relative section-app ${sectionSize} flex-1 ` + (bgColor ? bgColor : 'bg-gray-ed')}>
          <Outlet />
        </div>
        {HIDDEN_FOOTER ? '' : <Footer />}
      </div>
    </div>
  )
}

export default App;