import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from, fromPromise, toPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { makeAuthenticatedRequest } from './auth';

//return true if retry this type of error
const chkRetryErrorType = (error: any) => {
    let retry = false;

    function ChkMsg(info: string) {
        if (error?.networkError?.message.includes(info)) return true;
        if (error?.message.includes(info)) return true;
        return false;
    }

    //retry if ERR_CONNECTION_REFUSED
    if (!retry) retry = ChkMsg('ERR_CONNECTION_REFUSED');
    if (!retry) retry = ChkMsg('Failed to fetch');

    return retry;
};

const maxAttempts = 8;

// Configure the retry link
const retryLink = new RetryLink({
    attempts: {
        // Retry on network errors (e.g., 502 errors) or server errors (status codes 500-599)
        // Retry in error type list
        retryIf: (error, _operation) => {
            const shouldRetry: boolean = !!error &&
                (error.networkError ||
                    (error.response?.status >= 500 && error.response?.status <= 599) ||
                    chkRetryErrorType(error));

            if (shouldRetry) {
                // Track the number of retries
                _operation.setContext(({ retries = 1 }) => ({
                    retries: retries + 1,
                }));
            }

            return shouldRetry;
        },
        max: maxAttempts,//max attemps
    },
    delay: {
        // Exponential backoff
        initial: 500, // Initial delay in ms
        max: Infinity, // Maximum delay
        jitter: true, // Randomize the delay
    },
});

const authLink = new ApolloLink((operation, forward) => {
    return fromPromise(
        makeAuthenticatedRequest(operation)
            .then(() => {
                return toPromise(forward(operation));
            })
    );
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) console.log(`[Network error]: ${networkError}`);

    const retries = operation.getContext().retries || 0;
    // Reload the page if retry attempts exceed maxAttempts
    if (retries >= maxAttempts) {
        window.location.reload();
    }
});

export const client = new ApolloClient({
    link: from([retryLink, errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache()
});


