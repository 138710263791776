import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { usePWAInstall } from 'react-use-pwa-install';
import { isIOS, isMobile } from 'react-device-detect';
import Slide from '@mui/material/Slide';

import { IMG } from '../lib/imgLib';
import Logo from './img/FANSI-GO-logo-white.svg';
import __ from '../lib/gettext';

export const FooterBlock = () => {
    let currPath = useLocation().pathname;
    const [url, setUrl] = useState('');
    const [currMenu, setMenu] = useState(1);
    const pwaInstall = usePWAInstall();
    const [showPWA, setPWA] = useState(false);
    const [showIosPWA, setIosPWA] = useState(false);

    useEffect(() => {
        if (currPath !== url) {            
            setUrl(currPath);
            setMenu(1); //reset first
            let currUrl = currPath.toLocaleLowerCase();
            if (currUrl.includes('/collection')) setMenu(3);
        }
    }, [currPath, setUrl, url, setMenu])

    async function HandlePwaInstall() {
        setPWA(false);
        if (pwaInstall !== null) {
            await pwaInstall();
        }
    }

    function ShowPWA() {
        let lastShow = localStorage.getItem('FANSI-GO-PWA');
        if (!lastShow) lastShow = '0';
        let curr = new Date().getTime();
        let standAlone = window.matchMedia('(display-mode: standalone)').matches;
        // if (curr - parseInt(lastShow + '') > 86400 * 1000) { // show pwa popup once a day
            if (isIOS && isMobile && !standAlone)
                setIosPWA(true);
            setPWA(true)
            localStorage.setItem('FANSI-GO-PWA', curr + '');
        // }
    }

    return (
        <div className='fixed lg:relative bottom-0 left-0 w-full flex'>
            <div className='relative hidden lg:block w-full bg-gray-ed h-20 z-50'>
                <div className='relative section-md'>
                    <div className='aboslute left-0 top-0 w-full h-px bg-gray-b6' />
                    <div className='mt-7 flex'>
                        <div className='w-1/2 flex'>
                            <img className='mr-2' src={Logo} alt='logo' />
                            <p className='text-xs text-gray-b6 leading-none my-auto'>©2024 FANSI GO. All rights reserved.</p>
                        </div>
                        <div className='w-1/2 flex justify-end'>
                            <Link className=' my-auto mr-5' to='/service' target='_blank'>
                                <p className='text-xs text-gray-6d'>{__('Terms_of_Service')}</p>
                            </Link>
                            <Link className=' my-auto mr-5' to='/privacy' target='_blank'>
                                <p className='text-xs text-gray-6d'>{__('Privacy_Policy')}</p>
                            </Link>
                            <Link className=' my-auto mr-5' to='https://sweet-angle-e85.notion.site/FAQ-45d76bebcf1c4c02888dbc457ab98b3d' target='_blank'>
                                <p className='text-xs text-gray-6d'>{__('WEB_QA')}</p>
                            </Link>
                            <Link className=' my-auto mr-5' to='https://www.instagram.com/fansi_nft?igsh=MWZvZnJxZDBoMmgwMA==' target='_blank'>                            
                                <IMG src='IGGray' className='w-7 h-7 my-auto' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative flex lg:hidden w-full h-full bg-white' style={{ zIndex: 900, boxShadow: '0 -4px 10px 0 rgba(0, 0, 0, 0.17)' }}>
                <div className='relative section-app h-[72px] flex'>
                    <div className='mt-1 w-full flex justify-evenly pb-2'>
                        <Link className='w-12 ' to='/'>
                            <IMG className='w-8 h-8 mx-auto' src={currMenu === 1 ? 'HomeOrange' : 'Home'} />
                            <p className={'w-full text-center Roboto text-xs font-medium ' + (currMenu === 1 ? 'text-quest-red' : 'text-[#9d9d9d]')}>{__('FOOTER_HP')}</p>
                        </Link>
                        <div className='w-14 ' onClick={ShowPWA}>
                            <IMG className='mt-[2px] w-11 mx-auto' src='FANSIGO_LOGO' />
                            <p className={'mt-[2px] w-full text-center Roboto text-xs font-medium truncate ' + (currMenu === 1 ? 'text-quest-red' : 'text-[#9d9d9d]')}>{__('FOOTER_PWA_Create_ShortCut')}</p>
                        </div>

                        <Link className='w-12 ' to='/collection'>
                            <IMG className='w-8 h-8 mx-auto' src={currMenu === 3 ? 'CollectionOrange' : 'Collection'} />
                            <p className={'w-full text-center Roboto text-xs font-medium ' + (currMenu === 3 ? 'text-quest-red' : 'text-[#9d9d9d]')}>{__('FOOTER_Collection')}</p>
                        </Link>
                    </div>
                    {pwaInstall !== null ?
                        (showPWA && !isIOS ?
                            <Slide direction='up' in={showPWA} mountOnEnter unmountOnExit>
                                <div className='absolute w-full h-28 -top-[112px] px-4'>
                                    <div className='w-full h-24 bg-black text-white rounded-xl p-4 flex'>
                                        <IMG src='FANSIGO_S' className='h-full aspect-square mr-3' />
                                        <div className='w-full'>
                                            <p className='font-medium text-sm'>{__('FOOTER_PWA_INSTALL')}</p>
                                            <div className='w-full flex justify-end'>
                                                <button className='px-2 h-7 z-90 mr-6' onClick={() => { setPWA(false) }}>
                                                    <p className='w-full text-center text-xs my-auto'>{__('Not_Now')}</p></button>
                                                <button className=' rounded-3xl w-24 h-7 bg-[#ffc76e] z-90' onClick={HandlePwaInstall}>
                                                    <p className='w-full text-center text-gray-48 font-medium my-auto'>{__('FOOTER_PWA_Create_ShortCut')}</p></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            : '') : ''}

                    {showIosPWA ? <Slide direction='up' in={showIosPWA} mountOnEnter unmountOnExit>
                        <div className='absolute w-full px-8 -top-[340px]'>
                            <div className='w-full bg-black text-white rounded-xl p-6'>
                                <div className='w-full'>
                                    <div className='w-full flex justify-center'>
                                        <IMG className='w-16 h-16 mr-2' src='Safari' />
                                        <IMG className='w-6 my-auto mr-2' src='ArrowRight2' />
                                        <IMG className='w-16 h-16 mr-2' src='FANSIGO_S' />
                                    </div>
                                    <p className='mt-2 text-center font-medium'>{__('FOOTER_PWA_IOS')}</p>
                                    <img className='w-full mt-2' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Events/NeonOasis/ios-PWA.png' alt='pwa' />
                                    <div className='mt-2 w-full flex justify-center font-medium'>
                                        <p>{__('FOOTER_PWA_IOS_INFO1')}</p>
                                        <IMG className='w-4 my-auto mx-1' src='ShareOrange' />
                                        <p>{__('FOOTER_PWA_IOS_INFO2')}</p>
                                    </div>
                                </div>
                                <div className='w-full flex justify-center mt-6'>
                                    <button className='px-2 h-7' onClick={() => { setIosPWA(false) }}>
                                        <p className='w-full text-center text-xs my-auto underline'>{__('Not_Now')}</p></button>
                                </div>
                            </div>
                        </div>
                    </Slide> : ''}
                </div>
            </div>
        </div>
    )
}

const Footer = () => {
    const url = useLocation().pathname;

    const FOOTER_BLACKLIST = ['/corporation', '/login', '/tickets/events/', '/tickets/payment/', 'dashboard/', '/history/events', '/collection/transaction', '/apps/']
    let showFooter = true;
    FOOTER_BLACKLIST.forEach((blackList) => {
        blackList = blackList.toLowerCase();

        if (url.toLowerCase().includes(blackList)) {
            showFooter = false;
        }
    });


    return (
        <>
            {showFooter ?
                <FooterBlock /> : ''
            }
        </ >
    )
};

export default Footer;
