import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Carousel from 'react-material-ui-carousel'

import { SVG, IMG } from '../lib/imgLib';
import { convertDateTime } from '../lib/utils';
import __, { LangConveter } from '../lib/gettext';
import { Loading, LazyImg } from '../lib/components';


const EventBlock = ({ event }: any) => {
  const navigator = useNavigate();
  let onGoing = true;
  let info = __('HP_EVENT_RUNNING');
  if (new Date().getTime() > new Date(event.eventEnd).getTime()) {
    onGoing = false;
    info = __('HP_EVENT_ENDED');
  }
  let url = '/';
  switch (event.type) {
    case 1:
      url = '/Tickets/events/' + event.id;
      break;
    case 2:
      url = '/' + event.id;
      break;
  }

  function handleClick() {
    if (!onGoing) return;
    navigator(url);
  }

  const eventId = event.eventId ? event.eventId : event.id + 10000;

  return (
    <div className='relative rounded-xl bg-white' style={{ boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.15)' }}>
      <div className='relative w-full h-0 pb-[52.5%]'>
        <LazyImg onClick={handleClick} className='absolute top-0 w-full h-full rounded-t-xl cursor-pointer ' src={event.bannerUrl} alt={'banner' + event.id} />
      </div>
      <div className='p-4 leading-tight'>
        <p className='Roboto text-gray-76 font-medium truncate '>{LangConveter.getLocalText(event.artistName)}</p>
        <p className='Roboto font-semibold text-gray-48 text-lg truncate'>{LangConveter.getLocalText(event.eventName)}</p>
        <div className='flex mt-2'>
          <SVG src='Calendar' className='mr-1 my-auto' sx={{ width: 16, height: 15, color: '#767676' }} />
          <span className='text-sm Roboto font-medium text-gray-48'>{convertDateTime(event.eventStart).date + ' - ' + convertDateTime(event.eventEnd).date}</span>
        </div>        
        <div className={'absolute top-0 left-0 m-3 rounded-full px-[10px] py-[6px] border flex ' + (onGoing ? 'bg-white text-gray-48 border-gray-b6' : 'bg-gray-48 text-gray-ed border-white')}>
          <IMG src={onGoing ? 'Hourglass2' : 'Clock'} className='w-4 h-4 my-auto mr-1' />
          <p>{info}</p>
        </div>        
      </div>
      <div className='w-full h-px bg-gray-ed' />
      <div className='w-full p-4'>
        {onGoing ?
          <div className='w-full flex'>
            <div className='w-1/2 flex'>
              <Link to={'./collection/history/events/' + eventId} className='flex cursor-pointer my-auto'>
                <IMG src='InfoGray' className='w-5 h-5 my-auto mr-1' />
                <p className='text-base lg:text-sm xl:text-base font-medium my-auto'>{__('HP_CHK_EVENT')}</p>
              </Link>
            </div>
            <div className='w-1/2 flex justify-end'>
              <button onClick={handleClick} className='bg-teal-fg h-9 px-4 lg:px-2 flex rounded-4xl'>
                <p className='text-lg lg:text-sm xl:text-lg text-white font-medium my-auto mr-1'>{__('FGT_Purchase_Now')}</p>
                <IMG src='ArrowRight' className='h-5 w-5 my-auto' />
              </button>
            </div>
          </div> :
          <Link to={'./collection/history/events/' + eventId} className='w-full border border-gray-76 h-9 px-4 flex justify-center rounded-4xl cursor-pointer'>
            <IMG src='InfoGray' className='w-5 h-5 my-auto mr-1' />
            <p className='text-gray-76 text-lg font-medium my-auto'>{__('HP_CHK_EVENT')}</p>
          </Link>
        }
      </div>
    </div>
  )
}

const Home = () => {
  let eventList = [
    {
      id: 'FireEx2024NA',
      type: 2,
      eventName: '滅火器2024北美巡迴',
      bannerUrl: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Events/FireEx2024NA/FireEx_Home_Banner.jpg',
      artistName: '火氣音樂',
      eventStart: '2024-03-04 00:00:00-0400',
      eventEnd: '2024-03-24 02:59:59-0400',
      eventId: 4

    },
    {
      id: 'NeonOasis2024',
      type: 2,
      eventName: '2024 綠洲尋寶',
      bannerUrl: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Events/NeonOasis/Oasis-Banner-1.jpg',
      artistName: '綠洲霓虹音樂祭',
      eventStart: '2024-01-19 00:00:00+0800',
      eventEnd: '2024-01-21 23:59:59+0800',
      eventId: 2
    },
    {
      id: 'FireballMaster2023',
      type: 2,
      eventName: '2023 火球祭',
      bannerUrl: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI/Events/FireballMaster2023/fireball_master_logo.png',
      artistName: '火氣音樂',
      eventStart: '2023-11-25 00:00:00+0800',
      eventEnd: '2023-11-26 23:59:59+0800',
      eventId: 1
    },
  ]
  const [currEvent, setCurr] = useState<any[]>([]);
  const [passEvent, setPass] = useState<any[]>([]);
  const [maxHistory, setMaxHistory] = useState(3);
  const GET_EVENT_LIST = gql`
  query GetEventList {
      events:getFGEventList
  }`;
  const { loading } = useQuery(GET_EVENT_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: ((data: any) => {
      if (data.events) {
        try {
          for (let i = 0; i < data.events.length; i++) {
            let newEvent = JSON.parse(data.events[i]);
            eventList.push({
              ...{ type: 1 },
              ...newEvent
            });
          }
        } catch (err) {
          console.warn(err)
        }
      }
      SortEvents(eventList)
    })
  });

  function SortEvents(events: any[]) {
    let curr = new Date().getTime();
    let currE: any[] = [];
    let passE: any[] = [];
    for (let i = 0; i < events.length; i++) {
      let event = events[i];      
      if (curr < new Date(event['eventEnd']).getTime()) {
        currE.push(event);
      } else {
        passE.push(event);
      }
    }
    currE = currE.sort((a: any, b: any) => {
      let saleEndA = new Date(a['saleEnd']).getTime();
      let saleEndB = new Date(b['saleEnd']).getTime();
      let eventEndA = new Date(a['eventEnd']).getTime();
      let eventEndB = new Date(b['eventEnd']).getTime();
      if (saleEndA > curr && saleEndB > curr) {//both still on sale
        return saleEndA < saleEndB ? -1 : 1; // end early first
      } else if (saleEndA < curr && saleEndB < curr) {//both end sale
        return eventEndA < eventEndB ? -1 : 1; //end early first
      } else { // one of them still on sale
        return saleEndA > saleEndB ? -1 : 1; //on sale first
      }
    })

    passE = passE.sort((a: any, b: any) => {
      let eventEndA = new Date(a['eventEnd']).getTime();
      let eventEndB = new Date(b['eventEnd']).getTime();
      return eventEndA > eventEndB ? -1 : 1; //end later first
    })

    if (currE.length > 0) setCurr(currE);
    if (passE.length > 0) setPass(passE);
  }

  if (loading) return <Loading />

  let divIndex = 0;
  let history = 0;
  let historyIdx = 0;

  return (
    <div className='relative w-full pb-8 lg:desktop-min-height'>
      <div className='w-full relative pt-20 pb-2 bg-cover bg-[url("https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/fansi-go-body-bg.jpg")]'
        style={{ minHeight: 220, backgroundSize: 'cover', backgroundImage: 'url(https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_bg.png)' }}>
        
        <div className='w-full section-lg'>
          <Carousel cycleNavigation={false} swipe={true} animation='slide' navButtonsAlwaysInvisible={true}>
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/Tickets/events/19'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Tickets/Banner/Event-Banner-19.jpg?v=0820'} alt='banner' />
                </div>
              </a>
            </div>
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/Tickets/events/12'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_4.png'} alt='banner' />
                </div>
              </a>
            </div>
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/collection/Tickets'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_1.png'} alt='banner' />
                </div>
              </a>
            </div>
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/collection/Tickets'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_2.png'} alt='banner' />
                </div>
              </a>
            </div>
            <div className='relative mx-auto w-5/6 lg:w-2/5'>
              <a target='_blank' rel="noreferrer noopener" href={'https://go.fansi.me/collection/Tickets'}>
                <div className='relative w-full h-0 pb-[51.4%]'>
                  <LazyImg className='absolute rounded-xl w-full h-full' src={'https://fansi-static.s3.ap-southeast-1.amazonaws.com/FANSI-GO/Img/home_banner_3.png'} alt='banner' />
                </div>
              </a>
            </div>
          </Carousel>
        </div>
      </div>
      <div className='relative section-lg p-6 lg:px-24 xl:px-48'>
        {currEvent.length > 0 ?
          <div className='w-full flex lg:mt-12'>
            <p className='text-gray-48 text-xl font-semibold mr-4'>{__('HP_EVENT_RECENT')}</p>
            <p className='text-gray-76 text-xs font-medium my-auto'>{__('HP_EVENT_RECENT_SUBTITLE')}</p>
          </div> : ''}
        <div className='w-full flex flex-wrap'>
          {currEvent.map((event: any) => {
            let pad_lg = 'lg:pr-6';
            switch (divIndex % 3) {
              case 1:
                pad_lg = 'lg:px-3';
                break;
              case 2:
                pad_lg = 'lg:pl-6';
                break;
            }
            return (
              <div className={'w-full mt-4 lg:mt-9 lg:w-1/3 ' + pad_lg} key={'event' + (divIndex++)} >
                <EventBlock event={event} />
              </div>
            )
          })}
        </div>
        <div className='w-full flex mt-20'>
          <p className='text-gray-48 text-xl font-semibold mr-4'>{__('HP_EVENT_PASSED')}</p>
          <p className='text-gray-76 text-xs font-medium my-auto'>{__('HP_EVENT_PASSED_SUBTITLE')}</p>
        </div>
        <div className='w-full flex flex-wrap'>
          {passEvent.map((event: any) => {
            history++
            if (history <= maxHistory) {
              let pad_lg = 'lg:pr-6';
              switch (historyIdx % 3) {
                case 1:
                  pad_lg = 'lg:px-3';
                  break;
                case 2:
                  pad_lg = 'lg:pl-6';
                  break;
              }
              return (
                <div className={'w-full mt-4 lg:mt-9 lg:w-1/3 ' + pad_lg} key={'pass-event' + (historyIdx++)} >
                  <EventBlock event={event} />
                </div>
              )
            }
            return null;
          })}
        </div>
        {history > maxHistory ?
          <button onClick={() => setMaxHistory(9999)} className='mt-9 w-full border border-teal-fg rounded-lg h-9 flex justify-center'>
            <p className='my-auto text-teal-fg text-sm mr-2'>{__('Show_More') + __('HP_EVENT_PASSED')}</p>
            <IMG src='ArrowDownT' className='my-auto h-4 w-4' />
          </button> : ''}
      </div>      
    </div>
  )
}

export default Home;