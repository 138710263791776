import { Link } from "react-router-dom";
import { ScrollToTopOnce } from '../lib/components';

const Service = () => {
    return (<div className='relative md:w-full lg:w-4/5 md:max-w-[430px] lg:max-w-full mx-auto h-full pt-12 pb-14 px-6 Roboto text-black bg-white text-[14px]'>
        <ScrollToTopOnce />
        <p className='text-[22px] text-center font-bold'>FANSI GO售票系統服務條款</p>
        <p className='text-lg font-bold mt-5'>A. 會員認知與接受條款</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>龍獨斑股份有限公司（下稱「本公司」）依據本FANSI GO售票系統服務條款（下稱「本條款」）提供您（下亦稱「會員」）於FANSI GO售票系統購買活動票券及其他FANSI GO售票系統不定期更新之相關服務（下合稱「本服務」）。</li>
                <li className='mt-3'>為確保您的權益，請您詳閱本條款以下規定，並隨時注意本公司就本條款公告之相關修改或變更，本公司有權於任何時間修改或變更本條款之內容。</li>
                <li className='mt-3'>當您使用本服務時，可能會依據各特定服務之性質而須遵守本公司另行公告之服務條款或相關規定，該另行公告之服務條款或相關規定均為本條款之一部分。</li>
                <li className='mt-3'>當您開始使用本服務或於本條款修改或變更後繼續使用本服務，即表示您已閱讀、瞭解並同意接受本條款或該等修改或變更後之內容；若您無法遵守或不同意本條款之內容，或您所屬的國家或地區排除本條款內容之一部或全部時，請您立即停止使用本服務。為確保您的權益，建議您經常確認本條款之最新公告內容。</li>
                <li className='mt-3'>若您為未滿18歲之未成年人或無完全行為能力之人，除應符合上述規定外，請於您的法定監護人閱讀、瞭解並同意本條款及其後修改或變更後之所有內容後，方得使用或繼續使用本服務，否則請立即停止使用本服務。當您使用或繼續使用本服務時，即表示您已令您的法定監護人閱讀、瞭解並同意接受本條款及其後修改或變更之所有內容。</li>
                <li className='mt-3'>您瞭解並同意您於完成註冊及登入流程而正式成為本服務之會員時，您除須遵守本條款所有規定外，並應遵守本公司對於會員之其他相關規定，包括但不限於FANSI GO售票系統隱私權政策。</li>
            </ol>
        </div> 
        <p className='text-lg font-bold mt-5'>B. 智慧財產權之保護與著作權聲明</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>本服務所使用之軟體、介面、程式等內容，包括但不限於影音內容、著作、文字、圖片、影像、音樂、舞蹈、劇本、簽名、姓名、藝名、曲名、檔案、資訊、資料、程式架構、介面安排、介面設計等，除本條款有特別規定外，均由本公司或其他權利人依法擁有其智慧財產權。前述智慧財產權包括但不限於商標權、專利權、著作權、營業秘密與專有技術等，任何人未經同意不得擅自使用、修改、重製、公開播送、改作、散佈、出租、發行、公開傳輸、公開發表、進行還原工程、解編或反向組譯。尊重智慧財產權是您應盡的義務，如有違反，您應自負一切法律責任，並應對本公司之全部損害負擔賠償責任。</li>
                <li className='mt-3'>本公司行銷、宣傳本服務時，就本服務相關之商品、服務名稱、圖樣等（下稱「本服務商標」），依其註冊或使用之狀態，受中華民國商標法及公平交易法等之保護，未經本公司事前以書面同意，任何人不得以任何方式使用本服務商標。</li>
                <li className='mt-3'>為保障智慧財產權，若您個人之商標權、專利權、著作權等智慧財產權有受本公司侵害之虞，請儘速與本公司聯絡（本公司聯絡信箱為
                    <Link to={'mailto:support@fansi.me'}><span className='text-[#00b9c4] underline'>support@fansi.me</span></Link>
                    ），並提供侵害智慧財產權之具體資料予本公司，包括但不限於：</li>
                <ol className="ml-4" style={{ listStyleType: 'lower-alpha' }}>
                    <li className='mt-2'>主張遭受侵害之智慧財產權之描述；</li>
                    <li className='mt-2'>主張遭受侵害之智慧財產權於本服務中所在位置之描述；</li>
                    <li className='mt-2'>您的聯絡方式等。</li>
                </ol>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>C. 註冊義務</p>
        <p className='mt-3'>為使用本服務，您瞭解並同意下列事項：</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>您提供予本公司者均為您正確、最新及完整之資料。</li>
                <li className='mt-3'>您應隨時維持、更新您的個人資料。若您提供任何錯誤、不實或不完整的資料，本公司有權暫停或終止您的會員帳號，並拒絕您使用本服務之全部或一部。</li>
                <li className='mt-3'>完成使用本服務之註冊程序後，您將接收一組會員帳號及密碼，您應維持其機密與安全。</li>
                <li className='mt-3'>如您的上述會員帳號或密碼遭盜用或有其他安全問題發生時，您應立即通知本公司。</li>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>D. 活動票券購買</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>會員應確實依本服務指示及步驟購買活動票券，若因資料填寫錯誤、隱匿真實資料等情形，導致購票失敗、不能領票、不能入場或無法退票等狀況，會員應自行負責。</li>
                <li className='mt-3'>會員應自行確認購買活動票券之票種、位置、張數、演出時間等詳細資訊後，再行付款。</li>
                <li className='mt-3'>會員僅得依照各活動指定之票券付款方式進行付款，如會員成功購買活動票券，本服務將以電子郵件通知，且會員可以於本服務「會員訂單查詢」頁面查詢。</li>
                <li className='mt-3'>會員僅得依照各活動頁面公告指定之取票方式進行取票，如活動票券已傳輸至會員之個人電腦、手機或其他行動裝置，請依各活動頁面公告之使用規則辦理。</li>
                <li className='mt-3'>如會員成功購買活動票券後，有換票或退票需求，應依照各活動頁面公告之換票或退票規則辦理。</li>
                <li className='mt-3'>會員應持活動票券方得進場，並應遵守活動票券票面所載之規定，以及活動主辦單位及場館之各項規定。</li>
                <li className='mt-3'>本服務為受活動主辦單位委託提供票務服務，並非活動主辦單位，如會員對於各活動內容有相關問題，請參照主辦單位公告之訊息並自行洽詢主辦單位。本服務所刊載之活動內容及規則均係由活動主辦單位提供，本服務不負實質審查責任，如活動延遲、改期及相關事宜亦由活動主辦單位負責，本公司概不負責。</li>
                <li className='mt-3'>請勿於非正式授權售票之通路購票，非經由本服務購買活動票券所生之糾紛，會員應自行負責。</li>
                <li className='mt-3'>經由本服務售出之活動票券均依法向活動所在地之稅捐稽徵處報繳娛樂稅，無須就活動票券收入開立統一發票。</li>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>E. 會員行為</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>本公司之會員自行公開張貼或私下傳送的資訊、資料、文字、軟體、音樂、音訊、照片、圖形、視訊或其他資料（下稱「會員內容」），均由會員內容之提供者自負一切法律責任，本公司不負任何擔保或賠償之責。</li>
                <li className='mt-3'>於任何情形下，本公司均不為任何會員內容負責，包含但不限於任何錯誤或遺漏，及經由本公司所張貼、傳送或發送電子郵件等所衍生之一切損失或損害。</li>
                <li className='mt-3'>會員利用本服務所為之行為，基於下列情形，本公司得於合理範圍內，將其內容加以保存或揭露：</li>
                <ol className="ml-4" style={{ listStyleType: 'lower-alpha' }}>
                    <li className='mt-2'>依法令之規定；</li>
                    <li className='mt-2'>為執行本條款之約定；</li>
                    <li className='mt-2'>經第三人提出相關證據而請求預防、除去侵權行為或請求損害賠償；</li>
                    <li className='mt-2'>其他為保護本公司、會員及一般公眾生命、身體、財產安全之情形。</li>
                </ol>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>F. 會員之聲明與保證</p>
        <p className='mt-3'>為使用本服務，您聲明且保證下列事項：</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>您透過本服務所提供予本公司者，均為您正確、最新及完整之資料，且您同意隨時維持、並向本服務更新您的個人資料。</li>
                <li className='mt-3'>您透過本服務，向本公司以電子文件方式作成之任何或所有意思表示均健全有效且無瑕疵。</li>
                <li className='mt-3'>當您使用本服務時，應遵守中華民國法令之規定及相關網際網路之國際慣例，若您是中華民國管轄領域外之使用者，並應遵守所屬國家或地區之法令，不得基於任何非法目的或以任何非法方式使用本服務，亦不得利用本服務為侵害他人權益、違法或不當之行為，否則本公司有權立刻終止您使用本服務。</li>
                <li className='mt-3'>倘您違反本條各項會員之聲明與保證事項進而造成本公司之損害，您同意對本公司所受之一切損害負擔賠償責任（包括但不限於訴訟費用及律師費）。</li>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>G. 第三方資訊、程式或網站之連結</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>除各活動主辦單位所公告之資訊外，本服務不保證第三方資訊之正確性及安全性，您應自行判斷、評估第三方資訊之正確性或可信性，並自負風險，如會員因自第三方獲取資訊或購買活動票券所生的爭議，應由會員自行負責，本公司概不負責。</li>
                <li className='mt-3'>本公司或本公司合作夥伴可能提供其他程式或網路資源之連結，您將可能因此連結至其他業者所經營之程式或網站，惟本公司與該等業者並無任何關係。該等程式或網站上之商標權及其他權利仍歸相關權利人所有，本公司並未取得或授權您使用該等權利。</li>
                <li className='mt-3'>其他業者經營之程式或網站均由該業者自行負責，非屬本公司所控制或負責之範圍，本公司亦不擔保該等程式或網站內容的正確性、即時性、有效性、合法性、安全性及完整性。</li>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>H. 隱私權保護</p>
        <p className='mt-3'>本公司尊重您的隱私並致力於保護您的個人資料，於使用本服務前，請您詳細閱讀「FANSI GO售票系統隱私權政策」，當您使用本服務時，即視同您同意本公司依據「FANSI GO售票系統隱私權政策」蒐集、處理與利用您的個人資料。</p>
        <p className='text-lg font-bold mt-5'>I. Cookies</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>本公司將於您藉以瀏覽本服務之電腦、手機或其他行動裝置設定並取用cookies。</li>
                <li className='mt-3'>本公司允許於本服務上擺放廣告或活動之合作夥伴得於您使用的電腦、手機或其他行動裝置設定並取用cookies，同時並應適用各該合作夥伴所自訂之隱私權政策。</li>
                <li className='mt-3'>Cookies的使用在於追蹤您於本服務上的活動，以利本公司進行各種分析、資料蒐集，以提供您更便利、更完整之服務。</li>
                <li className='mt-3'>若您不願接受本公司設定之cookies，可透過網路瀏覽器的選項設定拒絕cookies，惟此舉可能造成您於使用本服務時受到限制。</li>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>J. 拒絕或終止您的使用</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>您瞭解並同意本公司得因各種考量（包含但不限於您一定期間未使用本服務、法院或政府機關之命令、依您的請求、本服務內容發生實質變更或無法預期的技術或安全問題、您有詐欺或違法之行為、您未依約支付費用，或本公司認為您已違反本條款之任一規定及其規範目的）而逕自終止您的會員帳號、密碼或使用本服務。若您遭本公司終止提供任何服務，本公司對您或其他第三人均不承擔任何法律責任。</li>
                <li className='mt-3'>您瞭解並同意您於本服務所使用之會員帳號係不可轉讓，且該會員帳號、會員帳號中之內容及所有權利，於您死亡或變為無行為能力人時即立刻終止，本公司並無繼續維持或保存該會員帳號及其內容之義務。</li>
                <li className='mt-3'>如有下列情形之一者，本公司將暫停或中斷本服務之全部或一部，本公司對您因而所受之直接或間接損害，均不負任何賠償責任：</li>
                <ol className="ml-4" style={{ listStyleType: 'lower-alpha' }}>
                    <li className='mt-2'>本公司相關軟、硬體設備進行遷移、更換、升級、保養或維護時；</li>
                    <li className='mt-2'>您有任何違反政府法令或本條款之情形者；</li>
                    <li className='mt-2'>因天災或其他不可抗力事由所致之服務停止或中斷；</li>
                    <li className='mt-2'>因其他不可歸責於本公司之事由所致之服務停止或中斷。</li>
                </ol>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>K. 兒童及青少年之保護</p>
        <p className='mt-3'>為確保兒童及青少年使用本服務的安全性，並避免其隱私權受到侵犯，法定監護人應盡到下列各項義務：</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>請審慎選擇適合兒童及青少年瀏覽之網站及/或程式。若係未滿12歲之兒童，於其瀏覽本服務時，法定監護人應全程陪伴在旁；若係12歲以上未滿18歲之青少年，則應斟酌是否同意其使用本服務。</li>
                <li className='mt-3'>應告知兒童及青少年勿隨意將自己或家人之資料填寫於本服務上或透露予他人知悉，欲提供相關個人資料前，請先檢閱「FANSI GO售票系統隱私權政策」。</li>
                <li className='mt-3'>請告知兒童及青少年勿隨意於本服務上與陌生人聯絡，亦不可接受其贈送之物品或與之單獨見面。</li>
                <li className='mt-3'>請告知兒童及青少年本服務上分享之圖片、音樂、影片或文字檔案仍受到著作權法的保護，請尊重著作權，勿以非法方式下載、重製任何類型之檔案。</li>
                <li className='mt-3'>請告知兒童及青少年，於本服務上有任何交易行為前，應注意交易之內容、金額及付款方式等，並請務必告知其法定監護人，以取得其同意。</li>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>L. 自我安全防護</p>
        <p className='mt-3'>您於瀏覽完畢本服務後，請登出並關閉瀏覽視窗，以確保您個人資料的安全。</p>
        <p className='text-lg font-bold mt-5'>M. 下載軟體或資料</p>
        <p className='mt-3'>於使用本服務或經由本服務連結至其他程式以下載軟體或資料前，您應慎重考慮、篩選相關軟體或資料，就該等軟體或資料之合法性、正確性、完整性、有效性及是否侵犯他人權益等事項，本公司不負任何擔保責任，對於您或第三人因此所受之一切損害，本公司均不負任何賠償責任。</p>
        <p className='text-lg font-bold mt-5'>N. 廣告</p>
        <p className='mt-3'>任何產品、服務之供應商或廣告商，於本服務所刊登之廣告內容、產品圖片或其他買賣資訊，本公司僅接受委託予以刊登，就其內容不作實質審查或任何修改，故本公司就前揭內容之正確性及可信度，不負任何擔保責任，您應對於廣告之正確性與可信度自行斟酌、判斷。</p>
        <p className='text-lg font-bold mt-5'>O. 免責聲明</p>
        <p className='mt-3'>您明確瞭解並同意以下規定：</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>本服務系統可能發生中斷或故障等現象，此或將造成您使用上的不便、資料喪失、錯誤、被竄改或其他財產上或非財產上損害等情形，建議您於使用本服務時，應隨時採取防護措施。您因使用或無法使用本服務所生之任何損害，除本公司具有故意或重大過失外，本公司不負任何賠償責任。</li>
                <li className='mt-3'>鑑於網路傳輸之特性，本公司無法保證所有透過本服務所進行交易之準確性、及時性，本公司亦不擔保均可及時提供本服務、本服務不受干擾或安全可靠免於出錯。</li>
                <li className='mt-3'>您因違反本條款所生之任何損害及損失，均由會員自行負責，本公司不負擔任何損害賠償或損失補償之責。</li>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>P. 通知</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>本條款之變更及其他依中華民國相關法令之規定須為通知之事項，本公司得以電子郵件、一般信件、簡訊、多媒體簡訊、文字訊息、公告於本服務或以其他合理之方式通知您，惟您若違反本條款而以未經授權的方式存取本服務內容時，您將可能不會收到前述通知。</li>
                <li className='mt-3'>當您經由本公司授權的方式使用相關服務時，即視同您已同意本公司任何傳送予您的通知均視為送達。</li>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>Q. 本條款之效力、準據法與管轄法院</p>
        <div className='w-full pl-4'>
            <ol className='list-decimal out-inside'>
                <li className='mt-3'>本條款構成您與本公司間之有效契約，本條款如有一部無效時，不影響其他條款之效力。</li>
                <li className='mt-3'>本條款之解釋與適用、與本條款有關之爭議，或因本條款而生之法律程序，均應以中華民國相關法令為準據法，並由臺灣臺北地方法院為第一審管轄法院。如您所在地之有關法律與上開法令規定相衝突時，該等規定於此不生效力。</li>
                <li className='mt-3'>本條款如有未盡之處，雙方應依誠實信用、平等互惠原則，共商解決之道。</li>
            </ol>
        </div>
        <p className='text-lg font-bold mt-5'>R. 服務變更</p>
        <p className='mt-3'>本公司保留得不經通知隨時修改、暫停或永久停止提供本服務之權利，您瞭解並同意本公司就此不負任何法律責任。</p>
        <p className='text-lg font-bold mt-5'>S. 其他規定</p>
        <p className='mt-3'>本條款中之標題均僅供參考之用，不影響本條款各該約款之解釋。</p>
        <div className="text-center">
            <button className='mt-12 w-1/2 h-11 px-[20px] bg-[#484848] rounded-[25px]' onClick={() => {
                window.location.href = window.location.origin + '/tickets/';
            }}>
                <p className='my-auto text-lg text-white font-medium'>回到首頁</p>
            </button>
        </div>
    </div>)
}


export default Service;