import path from 'ramda/es/path';
import { zh, en } from './dict-translation';

const DEFAULT_LANG = 'zh';
let _lang: string = DEFAULT_LANG;
let defaultLang: { [k: string]: string } = zh;

export const langList = ['en', 'zh']; // available language other then Chinese
export const langName: { [k: string]: string } = {
  en: 'English',
  zh: '繁體中文',
};

function AvailableLanguage(lang: string) {
  const langList = ['en', 'zh']; // available language other then Chinese
  if (langList.indexOf(lang) !== -1) {
    return lang;
  } else {
    return DEFAULT_LANG;
  }
}

export const setLanguage = (lang: string): boolean => {
  try {
    lang = AvailableLanguage(lang);
    _lang = lang;
    switch (_lang) {
      case 'zh':
        defaultLang = zh;
        break;
      default:
        defaultLang = en;
        break;
    }
    localStorage.setItem('fansi:lang', lang);
    return true;
  } catch (err) {
    return false;
  }
};

export const getLanguage = (): string => {
  return AvailableLanguage(_lang || path(['navigator', 'language'], window) || '');
};

// Hydrate user-set language if present
try {
  const lang = localStorage.getItem('fansi:lang');
  if (lang) {
    setLanguage(lang);
  } else {
    const newLang = DEFAULT_LANG;
    localStorage.setItem('fansi:lang', newLang);
    setLanguage(newLang);
  }
} catch (err) {
  console.warn('[error]: Could not set language ');
}

const get = (x: string): string => {
  if (x === null) return '';
  x = x.replace(/^GraphQL error:\s+?/i, ''); //remove graphql error
  if (defaultLang[x]) {
    return defaultLang[x];
  } else {
    return x;
  }
};

class langConveterCls {
  private static _instance: langConveterCls;
  private _lang: string = DEFAULT_LANG;
  private constructor() {
    let lang = localStorage.getItem('fansi:lang');
    this._lang = lang ? lang : DEFAULT_LANG;
  }

  public get lang() {
    return this._lang;
  }

  public setLanguage(lang: string): void {
    if (langList.indexOf(lang) > -1) {
      this._lang = lang;
      localStorage.setItem('fansi:lang', lang);
    }
  }

  public getLangName(lang: string): string {
    if (langName[lang]) return langName[lang];
    return '';
  }

  public getLocalText(info?: string, lang?: string): string {    
    if (!info) return 'NA';
    if (!lang || langList.indexOf(lang) < 0) lang = this._lang;    
    let text = '';
    try {
      if (typeof (info) === 'string') {
        text = info;
        let list = JSON.parse(info);

        if (list[lang] !== undefined) text = list[lang];        
      } else if (typeof (info) === 'object' && info[lang])
        text = info[lang];
    } catch (err) {
      text = info;
    }
    return text;
  }

  public AvailableLanguage(lang: string) {
    if (langList.indexOf(lang) !== -1) {
      return lang;
    } else {
      return DEFAULT_LANG;
    }
  }

  public InitialLocalText(): { [k: string]: string } {
    let info: { [k: string]: string } = {};
    langList.forEach((lang: string) => {
      info[lang] = 'NA';
    });
    return info;
  }

  public UpdLocalText(info: string, updContent: string, lang: string): string {
    if (!info) info = JSON.stringify(this.InitialLocalText());
    try {
      let list = JSON.parse(info);
      list[lang] = updContent;
      return JSON.stringify(list);
    } catch (err) {
      let list = this.InitialLocalText();
      list[lang] = updContent;
      return JSON.stringify(list);
    }
  }

  public ChkLocalText(info: string) {
    let errMsg: string[] = [];
    try {
      let list = JSON.parse(info);
      langList.forEach((lang: string) => {
        if (!list[lang]) errMsg.push('缺少 "' + langName[lang] + '" 內容');
      });
    } catch (err) {
      errMsg.push('結構錯誤');
    }
    return errMsg;
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}

export const LangConveter = langConveterCls.Instance;

export default get;
