import AppleStore from '../components/img/img-apple-store.svg';
import AlertSquare from '../components/img/icon-alert-square.svg';
import AlertSquareG from '../components/img/icon-alert-square-gray.svg';
import AlertSquareT from '../components/img/icon-alert-square-teal.svg';
import AlertSquareR from '../components/img/icon-alert-square-red.svg';
import ArrowDown from '../components/img/icon-arrow-down.svg';
import ArrowDownB from '../components/img/icon-arrow-down-black.svg';
import ArrowDownBlue from '../components/img/icon-arrow-down-blue.svg';
import ArrowDownT from '../components/img/icon-arrow-down-teal.svg';
import ArrowLeft from '../components/img/icon-arrow-left.svg';
import ArrowLeftBa from '../components/img/icon-arrow-left-black.svg';
import ArrowRight from '../components/img/icon-arrow-right.svg';
import ArrowRight2 from '../components/img/icon-arrow-right-2.svg';
import ArrowRightBa from '../components/img/icon-arrow-right-black.svg';
import ArrowRightTeal from '../components/img/icon-arrow-right-teal.svg';
import ArrowUp from '../components/img/icon-arrow-up.svg';
import ArrowUpB from '../components/img/icon-arrow-up-blue.svg';
import ArrowUpBlack from '../components/img/icon-arrow-up-black.svg';
import ArrowUpR from '../components/img/icon-arrow-up-red.svg';
import Chair from '../components/img/icon-chair.svg';
import CheckOrange from '../components/img/icon-check-orange.svg';
import Clock from '../components/img/icon-clock-white.svg';
import ClockSquare from '../components/img/icon-clock-square.svg';
import Close from '../components/img/btn-bgc-close.svg';
import CloseBlack from '../components/img/btn-bgc-close-black.svg';
import CloseC from '../components/img/icon-close-clear.svg';
import CloseOrange from '../components/img/btn-bgc-close-orange.svg';
import Collection from '../components/img/icon-collection.svg';
import CollectionOrange from '../components/img/icon-collection-orange.svg';
import Copy from '../components/img/icon-copy.svg';
import Discord from '../components/img/icon-discord.svg'
import DiscordB from '../components/img/icon-discord-blue.svg'
import ExclamationBlack from '../components/img/icon-exclamation-black.svg';
import Explore from '../components/img/icon-explore.svg'
import ExploreWhite from '../components/img/icon-explore-white.svg'
import Fail from '../components/img/icon-fail.svg'
import FANSI from '../components/img/icon-fansi.svg'
import FANSIGO from '../components/img/icon-FANSI-GO.svg'
import FANSIGO_I from '../components/img/icon-FANSI-GO-invert.svg'
import FANSIGO_LOGO from '../components/img/icon-FANSI-GO-logo.svg'
import FANSIGO_LOGO_Gray from '../components/img/icon-FANSI-GO-logo-gray.svg'
import FANSIGO_S from '../components/img/icon-fansi-go-small.svg'
import FB from '../components/img/icon-facebook.svg';
import FBWhite from '../components/img/icon-facebook-white.svg';
import Files from '../components/img/icon-file.svg';
import Gear from '../components/img/icon-gear.svg';
import Google from '../components/img/icon-google.svg';
import GooglePlay from '../components/img/img-google-play.svg';
import Hourglass from '../components/img/icon-hourglass.svg';
import Hourglass2 from '../components/img/icon-hourglass-2.svg';
import Home from '../components/img/icon-home.svg';
import HomeOrange from '../components/img/icon-home-orange.svg';
import IG from '../components/img/icon-instagram.svg';
import IGGray from '../components/img/icon-instagram-gray.svg';
import Info from '../components/img/icon-info.svg';
import InfoBlue from '../components/img/icon-info-blue.svg';
import InfoGray from '../components/img/icon-info-gray.svg';
import InfoTeal from '../components/img/icon-info-teal.svg';
import InfoWhite from '../components/img/icon-info-white.svg';
import LinePay from '../components/img/icon-linepay.png';
import Link from '../components/img/icon-link-white.svg';
import Linkedin from '../components/img/icon-linkedin.svg';
import LinkTree from '../components/img/icon-link-tree.svg';
import LinkTreeB from '../components/img/icon-link-tree-blue.svg';
import Location from '../components/img/icon-location.svg';
import LocationOrange from '../components/img/icon-location-orange.svg';
import Logout from '../components/img/icon-logout.svg';
import Logo from '../components/img/FANSI_MB_logo.svg';
import LogoFull from '../components/img/icon-fansi-logo-full.svg';
import MM from '../components/img/icon-metamask.png';
import Medale from '../components/img/icon-medale.svg';
import MedaleOrange from '../components/img/icon-medale-orange.svg';
import Menu from '../components/img/icon-menu.svg';
import Member from '../components/img/icon-member-white.svg';
import MetaBoom from '../components/img/icon-MetaBoom.svg';
import NoEvent from '../components/img/icon-no-active.svg';
import OpenSea from '../components/img/icon-opensea.svg';
import Pause from '../components/img/btn-pause.svg';
import Play from '../components/img/btn-play.svg';
import Question from '../components/img/icon-question.svg';
import QuestionTeal from '../components/img/icon-question-teal.svg';
import QRCode from '../components/img/icon-qr-code.svg';
import QRCodeBlack from '../components/img/icon-qr-code-black.svg';
import RisingStars from '../components/img/icon-rising-stars.svg';
import RoundCArrowLeft from '../components/img/round-carousel-arrow-left.svg';
import RoundCArrowRight from '../components/img/round-carousel-arrow-right.svg';
import Safari from '../components/img/icon-Safari.png';
import Scan from '../components/img/icon-scan.svg';
import ShareOrange from '../components/img/icon-share-orange.svg';
import ShareTicket from '../components/img/icon-share-ticket.svg';
import Shoeprint from '../components/img/icon-shoeprints.svg';
import Star from '../components/img/icon-doodle-star-rainbow.svg';
import StarBlack from '../components/img/icon-doodle-star-black.svg';
import StarEmpty from '../components/img/icon-star.svg';
import SeatClip from '../components/img/icon-seat-clip.svg';
import SeatPath from '../components/img/icon-seat-path.svg';
import Ticket from '../components/img/icon-ticket.svg';
import TicketH from '../components/img/icon-ticket-h.svg';
import Trashcan from '../components/img/icon-trashcan.svg';
import TrophyBronze from '../components/img/icon-trophy-bronze.svg';
import TrophyGold from '../components/img/icon-trophy-gold.svg';
import TrophySilver from '../components/img/icon-trophy-silver.svg';
import Twitter from '../components/img/icon-twitter.svg'
import TwitterB from '../components/img/icon-twitter-blue.svg'
import Wallet from '../components/img/icon-wallet-white.svg';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MenuIcon from '@mui/icons-material/Menu';
import IosShareIcon from '@mui/icons-material/IosShare';
import CropFreeIcon from '@mui/icons-material/CropFree';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

export const IMG = ({ src, className, style, onClick }: { src: string, className?: string, style?: React.CSSProperties, onClick?: any }) => {
    let img = Info;
    const List: { [k: string]: string } = {
        AppleStore,
        AlertSquare,
        AlertSquareG,
        AlertSquareT,
        AlertSquareR,
        ArrowDown,
        ArrowDownB,
        ArrowDownBlue,
        ArrowDownT,
        ArrowLeft,
        ArrowLeftBa,
        ArrowRight,
        ArrowRight2,
        ArrowRightBa,
        ArrowRightTeal,
        ArrowUp,
        ArrowUpB,
        ArrowUpBlack,
        ArrowUpR,
        Chair,
        CheckOrange,
        Clock,
        ClockSquare,
        Close,
        CloseBlack,
        CloseC,
        CloseOrange,
        Collection,
        CollectionOrange,
        Copy,
        Discord,
        DiscordB,
        ExclamationBlack,
        Explore,
        ExploreWhite,        
        Fail,
        FANSI,
        FANSIGO,
        FANSIGO_I,
        FANSIGO_LOGO,
        FANSIGO_LOGO_Gray,
        FANSIGO_S,
        FB,
        FBWhite,
        Files,
        Gear,
        Google,
        GooglePlay,
        Hourglass,
        Hourglass2,
        Home,
        HomeOrange,
        IG,
        IGGray,
        Info,
        InfoBlue,
        InfoGray,
        InfoTeal,
        InfoWhite,        
        Link,
        Linkedin,
        LinkTree,
        LinkTreeB,
        LinePay,
        Location,
        LocationOrange,
        Logout,
        Logo,
        LogoFull,
        MM,
        Medale,
        MedaleOrange,
        Menu,
        Member,
        MetaBoom,
        NoEvent,
        OpenSea,
        Pause,
        Play,
        Question,
        QuestionTeal,
        QRCode,
        QRCodeBlack,
        RisingStars,
        RoundCArrowLeft,
        RoundCArrowRight,        
        Safari,
        Scan,
        ShareOrange,
        ShareTicket,
        Shoeprint,
        Star,
        StarBlack,
        StarEmpty,
        SeatClip,
        SeatPath,
        Ticket,
        TicketH,
        Trashcan,
        TrophyBronze,
        TrophyGold,
        TrophySilver,
        Twitter,
        TwitterB,
        Wallet,

    }
    if (List[src]) img = List[src];
    return <img className={className ? className : ''} src={img} onClick={onClick ? onClick : null} style={style ? style : {}} alt={src} />
}

export const SVG = ({ src, sx, className, button, onClick }: { src: string, sx?: React.CSSProperties, className?: string, pure?: boolean, button?: boolean, onClick?: any }) => {
    if (!className) className = ''
    let icon = <PersonIcon className={className} sx={sx ? sx : {}} />;
    switch (src) {
        case 'Add':
            icon = <AddIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'ArrowRight':
            icon = <ArrowForwardIosIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'ArrowUp':
            icon = <KeyboardArrowUpIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'Calendar':
            icon = <CalendarMonthIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'Clock':
            icon = <AccessTimeOutlinedIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'Info':
            icon = <ErrorOutlineOutlinedIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'Location':
            icon = <LocationOnOutlinedIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'menu':
            icon = <MenuIcon className={className} sx={sx ? sx : {}} />;
            break;
        case 'More':
            icon = <MoreHorizIcon className={className} sx={sx ? sx : {}} onClick={onClick ? onClick : null} />;
            break;
        case 'MoreV':
            icon = <MoreVertIcon className={className} sx={sx ? sx : {}} onClick={onClick ? onClick : null} />;
            break;
        case 'Remove':
            icon = <RemoveIcon className={className} sx={sx ? sx : {}} />;
            break;    
        case 'Check':
            icon = <CheckCircleIcon className={className} sx={sx ? sx : {}} />;
            break;  
        case 'Cancel':
            icon = <CancelIcon className={className} sx={sx ? sx : {}} />;
            break;  
        case 'Trophy':
            icon = <EmojiEventsIcon className={className} sx={sx ? sx : {}} />;
            break;  
        case 'Share':
            icon = <IosShareIcon className={className} sx={sx ? sx : {}} />;
            break;           
        case 'Crop':
            icon = <CropFreeIcon className={className} sx={sx ? sx : {}} />;
            break;    
        case 'ZoomIn':
            icon = <ZoomInIcon className={className} sx={sx ? sx : {}} />;
            break;    
        case 'ZoomOut':
            icon = <ZoomOutIcon className={className} sx={sx ? sx : {}} />;
            break;     
        case 'Help':
            icon = <HelpIcon className={className} sx={sx ? sx : {}} />;
            break;           
    }
    
    if (button) return <IconButton >{icon}</IconButton >
    return icon;
}