export const zh = {
    DC_INVITE_LINK: 'https://discord.gg/vtkV88bN2E',
    FANSI_IG: 'https://www.instagram.com/fansi_nft',
    FANSI_Twitter: 'https://twitter.com/metaboom_nft',
    FANSI_LinkTree: 'https://linktr.ee/fansi_nft',
    FANSI_OpenSea: 'https://opensea.io/collection/metaboom-nft',
    MetaBoom_URL: 'https://metaboom.fansi.me',
    RisingStars_URL: 'https://metaboom.fansi.me/hatching',
    APP_Google: 'https://play.google.com/store/apps/details?id=me.fansi.app',
    APP_Apple: 'https://apps.apple.com/tw/app/metaboom/id6444411102',
    About: '關於',
    Lang_Zh: '繁中',
    Lang_En: 'EN',
    Google_Login: 'Google Login',
    Facebook_Login: 'Facebook Login',
    Login_N_Agree: '登入即代表您已詳閱並同意',
    Terms_of_Service: '服務條款',
    Privacy_Policy: '隱私政策',
    WEB_QA: '常見問題',
    Home_Desc: 'FANSI GO: Web3票務新體驗，現場表演更珍貴',
    Web_Default_Desc: '數位票根隨時紀錄不遺失，贏取更多表演福利回饋。',

  //Special
  FG_FANSILAND_INFO: '您將在三至五個工作天，\n收到票券兌換系統信',

  //票卷頁面 FANSI GO Tickets
  FGT_Select_Show: '請選擇活動場次進行購票',
  FGT_Show_List: '活動場次',
  FGT_Event_Info: '活動簡介',
  FGT_Pick_Section: '選擇票券種類',
  FGT_Sale_End: '結束販售',
  FGT_Sale_Start_At: '開始販售',
  FGT_Not_Sale_Yet: '尚未開賣',
  FGT_Add_Calendar: '+ 行事曆',
  FGT_Section_Usage: '票券可使用時間',
  FGT_Section_Info: '票券說明',
  FGT_Ticket_Purchased: '你已經購買 {0} 張，',
  FGT_Ticket_Available: '本場次還可以購買 {0} 張。',
  FGT_Show_Purchased_Max: '本場次購買額度已達上限。',
  FGT_Purchased_Max: '已達購買上限',
  FGT_Whitelist_Warning: '您不在優惠名單',
  FGT_Sale_Start: '本活動開始販售時間為',
  FGT_Back_To_Event_Page: '回到訂購頁面',
  FGT_Purchase_Now: '立即購票',
  FGT_Uncomplete_Order: '你有一筆未完成的訂單，要繼續嗎？',
  FGT_Purchase_Limit_Info: '單一場次能購買上限為 {0} 張',
  FGT_SEC_Tickets_Remaion: '（ 剩{0}張 ）',
  FGT_Select_Seat: '選擇座位',
  FGT_Reset_Seat: '重選所有座位',
  FGT_No_Seat: '尚未選位',
  FGT_Select_Seat_Result: '選位結果',
  FGT_Select_No: '購票數量',
  FGT_Seat_Section: '您的座位區域為',
  FGT_Seat_Info: '圖示說明',
  FGT_Seat_Empty:'空位',
  FGT_Seat_Selected:'你選的',
  FGT_Seat_Sold: '已售出',
  FGT_Seat_Confirm: '確認座位',
  FGT_Seat_Cancel: '取消座位',
  FGT_Seat_Current: '你目前選擇的是',
  FGT_Seat_Cancel_Current: '你確定要取消',
  FGT_Remain: '保留',
  FGT_Seat_Reset: '重選所有座位',
  FGT_Seat_Checkout: '點擊下一步，代表你已確認座位資訊。',
  'FGRT-ERR-2': '票卷預留失敗，請重新嘗試',
  'FGRT-ERR-3': '超過購買上限',
  'FGRT-ERR-4': '剩餘票卷不足',
  'FGRT-ERR-5': '訂單資訊錯誤，請重新嘗試',
  'FGRT-ERR-6': '尚有未付款的訂單，請先完成付款',
  'FGT-ReserveSeat-11': '選位錯誤',
  'FGT-ReserveSeat-12': '選位錯誤',
  'FGT-ReserveSeat-13': '位置已被選取',
  'FGT-ReserveSeat-14': '選位錯誤',

  //payment page
  'FGRD-ERR-11': '錯誤的序號，請重新輸入',
  'FGRD-ERR-12': '序號已被其他訂單使用',
  'FGRD-ERR-13': '序號不可用在此場次',
  'FGRD-ERR-14': '序號過期',
  'FGRD-ERR-15': '超過使用次數',
  'FGRD-ERR-16': '優惠碼被搶光了',
  'FGRD-ERR-21': '發生錯誤，請重新嘗試。',
  FGPP_Handling: '，手續費',
  FGPP_Line_Pay: '行動支付',
  FGPP_Aftee: '先享後付',
  FGPP_Aftee_Pay: '超商/ATM/轉帳',
  FGPP_Paypal_Pay: '境外支付',
  FGPP_Cash: '現場支付',
  FGPP_Cash_Pay: '現金支付',
  FGPP_CreditCard: '信用卡付款',
  FGPP_CreditCard_Pay: '信用卡支付',
  FGPP_Crypto_Pay: '加密貨幣支付',
  FGPP_Payment_Completed: '已完成付帳',
  FGPP_Ticket: '取票付款',
  FGPP_Free: '免費票',
  FGPP_Payment_Method: '付款方式',
  FGPP_Payment_Warning: '您的訂單已保留，請在 15 分鐘內完成訂單。\n若逾期未完成，系統將取消您的訂單',
  FGPP_Checkout_Warning: '已結帳後無法變更',
  FGPP_Billing_Detail: '金額明細',
  FGPP_Third_Party_Handling: '第三方金流手續費',
  FGPP_Notice: '注意事項',
  FGPP_Note_1: '請確實核對訂購內容與價格，本系統未提供換票服務。',
  FGPP_Note_2: '如您購買了複數張票，請與同行人一起驗票入場。',
  FGPP_Note_3: '本系統採QR Code電子票券形式，系統將於開演前24小時展示QR Code。',
  FGPP_Note_4: '退票時間範圍：購票後3日內。如購買時距表演已不足三天，則最後退票時效為表演開始前2小時',
  FGPP_Note_5: '退款方式：',
  FGPP_Note_6: 'Line pay：全額退款。',
  FGPP_Note_7: 'AFTEE：全額退款。',
  FGPP_Note_8: 'Paypal：票價全額退款，不包含8%手續費。',
  FGPP_Note_9: 'USDT(Polygon)：收取4％退票手續費。',
  FGPP_Note_10: '現金支付：不可退票。',
  FGPP_Note_11: '信用卡: 收取4％退票手續費。',
  FGPP_Confirm_Order_Info: '確認訂單內容',
  FGPP_Total_Amount: '總金額',
  FGPP_Next_Step_Warning: '點擊下一步，代表你已詳閱並同意',
  FGPP_Pre_Step: '上一步',
  FGPP_Enter_Checkout: '進行結帳',
  FGPP_Payment_Refund_Warning: '請留意退票時效且退款金額可能不包含手續費',
  FGPP_QRcode_Warning: '請出示 QR-Code 給相關工作人員掃描',
  FGPP_Cash_Confirm: '檢查交易結果',
  FGPP_Cash_Confirm_Warning: '請在交易完成後，點擊“檢查交易結果”',  
  FGPP_Payment_Uncomplete: '尚未完成付款',
  FGPP_Payment_Method_Error: '結帳方式錯誤',
  FGPR_Aftee_Warning: '此筆訂單已成立，若逾期未繳費，此訂單並不會自動取消。請於AFTEE規定時限內繳款，以避免產生滯納金。',
  FGPP_Order_Expired: '訂單已逾期，請重新訂票',
  FGPP_Start_Checkout: '開始結帳',
  FGPP_NextStep_Warning: '開始結帳後，將無法變更\n付款方式及優惠碼',
  FGPP_SEAT_INFO: '座位資訊',
  FGPP_SEAT: '座位',
  FGPP_Aftee_Warning_1: 'AFTEE將代您支付費用。您需向AFTEE完成付款，否則將產生滯納金。',
  FGPP_Aftee_Warning_2_1: '未付款不會自動取消訂單',
  FGPP_Aftee_Warning_2_2: '。請務必至AFTEE查看並繳清款項。',
  FGPP_Aftee_Warning_3: '如需退票，請於退票時效內在FANSI GO票券頁操作。',
  
  //payment result
  FGPR_Payment_Complete: '恭喜訂單已成立',
  FGPR_Payment_Complete_Semi: '實際狀況以收到票券為主',
  FGPR_Crypto_Warning: '請留意實際完成鏈上驗證，方完成支付。',
  FGPR_Ticket_Confirm: '到”票券”確認',
  FGPR_Trans_Fail: '交易失敗\n沒關係，再重新一次',

  //票夾頁面
  FGC_Ticket: '票券',
  FGC_Ticket_No: '票號',
  FGC_Original_Price: '原價',
  FGC_Ticket_Not_Own: '必須收回所有的票卷才能進行退票',
  FGC_Refund: '進行退票',
  FGC_Confirm_Refund: '確認退票',
  FGC_Refund_Fail: '退票失敗',
  FGC_Not_Refundable: '逾期無法退票',
  FGC_Not_Aavailable: '尚未開放',
  FGC_Event_Ended: '已結束',
  FGC_Redeemed: '已兌換',
  FGC_Not_Redeem: '未兌換',
  FGC_Barcode_Notice1: '請避免使用深色模式，保持白色背景，調高螢幕亮度',
  // FGC_Barcode_Notice2: '請將手機螢幕亮度調至最高，以利掃碼進場',
  FGC_Barcode_Notice3: '入場票券 QR-CODE\n將於開演前24小時開放',
  FGC_Owner: '聯絡人',
  FGC_My_Transactions: '我的訂單記錄',
  FGC_Zero_Ticket_Notice1: '你還沒有任何票券',
  FGC_Zero_Ticket_Notice2: '購買成功的票券都會在這邊顯示',
  FGC_Ticket_Tax_No: '行政票號',
  FGC_Ticket_Tax_No_Warning: '本娛樂票券經台北市稅捐稽徵處１１３年０４月０３日大安乙字第１１３５３０２３９７號核准使用，並已向演出之台北市稅捐稽徵機關申請報繳娛樂稅，內含代徵娛樂稅',
  FGC_Ticket_Tax_Free: '本娛樂票券經台北市稅捐稽徵處１１３年０４月０３日大安乙字第１１３５３０２３９７號核准使用，並已向演出之台北市稅捐稽徵機關申請報繳娛樂稅，不含代徵娛樂稅',
  //分票
  FGC_Share: '我要分票',
  FGC_Generate_Link: '產生領票連結',
  FGC_Generating: '產生中...',
  FGC_Share_Warning: '請留意分享對象，除非對方退還，否則無法收回/退票。\n 你的票券被領取後，會轉移至分享對象的票匣。',
  FGC_Copied: '已複製',


  //折扣頁面
  FGD_Discount: '折扣',
  FGD_Discount_Expired: '優惠已逾期',
  FGD_Expire_On: '使用期限',
  FGD_Coupon_Code: '優惠碼',
  FGD_Coupon_Warning: '請注意一組優惠碼只可以折抵一張訂單',
  FGD_Zero_Coupon: '目前還沒有優惠',
  FGD_Zero_Coupon_Info: '持續關注，優惠出現會在第一時間通知你',

  //足跡頁面
  FGP_Badge_Info: '徽章資訊',
  FGP_Claimed_Date: '取得日期',
  FGP_Unclaim: '未取得',
  FGP_Event_Holder: '主辦單位',
  FGP_Badge_Collection: '活動徽章圖鑑',
  FGP_My_Footprints: '我的探險足跡',
  FGP_No_Footprints: '目前還沒有探險足跡',
  FGP_My_Footprints_Note: '你參與過的活動都會顯示在這裡',

  //個人資訊
  FGU_Edit_Profile: '編輯個人資訊',
  FGU_Display_Name: '暱稱',
  FGU_Real_Name: '真實姓名',
  FGU_Explorer: '探險家',
  FGU_Footprint: '歷程足跡',
  FGU_Ticket: '票劵',
  FGU_Coupon_List: '優惠清單',
  FGU_Personal_Info: '聯絡人資訊',
  FGU_Name_Warning: '請輸入真實姓名，以利入場驗證',
  FGU_Gender: '性別',
  FGU_Birthday: '生日',
  FGU_Member_Card: '會員卡',
  FGU_Member_Card_Title: '專屬會員卡',
  FGU_Member_Card_Fill: '完成以下資料\n',
  FGU_Member_General: '一般會員',
  FGU_Member_Unapproved: '尚未開卡',
  FGU_Member_Activate: '啟用會員卡',
  FGU_Member_Date: '開卡日期',
  FGU_Member_Point: '會員集點',
  FGU_Member_Point_Warning: '請再次確認集點金額',
  FGU_Member_Pos_Amount: '消費金額',
  FGU_Member_Neg_Amount: '沖銷金額',
  FGU_Member_Point_Pos: '切換回正數',
  FGU_Member_Point_Neg: '切換成負數',
  
  //交易頁面
  FGTR_Transaction_Fail: '交易失敗',
  FGTR_Transaction_Completed: '交易完成',
  FGTR_Wait_Confirm: '待確認',
  FGTR_Wait_Payment: '待付款',
  FGTR_Cancel_Order: '交易取消',
  FGTR_Refunded: '已退票',
  FGTR_Show: '場次',
  FGTR_Section: '票種',
  FGTR_Order_Id: '單號',
  FGTR_Amount: '數量',
  FGTR_Order_Date: '訂購時間',
  FGTR_My_Transaction: '我的訂單記錄',
  FGTR_Zero_Order1: '沒有任何訂單紀錄',
  FGTR_Zero_Order2: '交易的結果都會記錄在這邊',
  FGTR_Payment_Method: '請選擇付款方式',
  FGTR_My_Ticket: '我的票卷',
  FGTR_Ticket_Note: '你的所有票券一目了然',

  //Event Info
  FGEI_Organizer: '主辦單位',
  FGEI_Ranking: '活動排行',
  FGEI_Ur_Rank: '你的排行',  
  FGEI_Leaderboard: '排行榜',
  FGEI_Leaderboard_Notes: '排行榜之用途以主辦單位公佈的活動規則為主',
  FGEI_Score: '個人積分',
  FGEI_Score_Record: '積分紀錄',
  FGEI_Persional_Score_Record: '個人積分紀錄',
  FGEI_Introduction: '活動任務簡介',
  FGEI_Event_Score :'活動積分',
  FGEI_Event_Score_Fixed: '修正積分',
  FGEI_Score_Notes: '個人積分之用途以主辦單位公佈的活動規則為主',
  FGEI_Badges: '活動徽章',
  FGEI_Badges_Notes: '徽章獲得方式，以活動規則為主',
  FGEI_No_Event: '目前還沒有活動徽章',
  FGEI_No_Event_Notes: '持續關注，有任務出現會在第一時間通知你',
  FGEI_Available: '可領取',
  FGEI_Obtained: '已取得',
  FGEI_Unobtained: '未取得',
  FGEI_Event_Info: '任務資訊',
  FGEI_Share_Now: '立即分享',
  FGEI_Badge_Notes: '徽章實際用途以主辦單位公佈的活動規則為主',
  FGEI_Share_Event: '分享活動',
  FGEI_Share_To: '分享到',
  FGEI_Copy_Link: '複製連結',  
  FGEI_Share_Notes: '地球上的大家！為了讓更多人認識這個表演者！請大家把力量借給他吧！',

  //Home
  HP_EVENT_RUNNING: '活動進行中',
  HP_EVENT_ENDED: '活動已結束',
  HP_EVENT_RECENT: '進行中活動',
  HP_EVENT_RECENT_SUBTITLE: '正在進行的精彩不容錯過',
  HP_EVENT_PASSED: '已結束的活動',
  HP_EVENT_PASSED_SUBTITLE: '你的足跡將為你帶來獨家優惠',
  HP_CHK_EVENT: '查看活動任務',

  //footer
  FOOTER_PWA_INSTALL: '建立 FANSI GO 捷徑到手機桌面，讓你更方便開啟喔！',
  FOOTER_PWA_IOS: '請使用Safari建立 FANSI GO 捷徑',
  FOOTER_PWA_IOS_INFO1: '點擊下方',
  FOOTER_PWA_IOS_INFO2: '然後選擇『加入主畫面』',
  FOOTER_PWA_Create_ShortCut: '建立捷徑',
  FOOTER_HP: '首頁',
  FOOTER_Collection: '個人蒐藏',

  //System
  SYS_CHK_NOW: '立即查看',
  SYS_Quest_notice: '你有 {0} 個新任務，快來看看！',

  //領票連結
  APP_TICKET_CLAIM: '領取票券',
  APP_TICKET_CLAIMED: '領取成功',
  APP_TICKET_CHECK: '到”票券”確認一下',
  APP_TICKET_CLAIM_NOW: '立即領取',
  APP_TICKET_INVALID: '連結已經失效',
  APP_TICKET_INVALID_INFO: '連結可能已被領取或者錯誤\n請確認連結來源或活動主辦方 ',   
  APP_TICKET_LOGIN: '登入後即可領取\n建議使用Chrome瀏覽器',
  APP_TICKET_ACCOUNT_CONFIRM: '請留意帳號是否正確',

  //通用
  Close: '關閉',
  Cancel: '取消',
  Cancel_Order: '取消訂單', 
  Cancel_Order_Confirm: '確認取消訂單',
  Close_Window: '關閉視窗',
  Copied: '已複製',
  detail_info: '詳細資訊',
  Edit: '編輯',
  Expired: '已失效',  
  Order_Id: '訂單編號',
  Purchased_Date: '訂購時間',
  Redeem: '查看',
  Save: '儲存',
  more: '更多',
  Sold_Out: '已售完',
  Ticket_Due: '使用期限',
  Input: '輸入',
  Show_Less: '顯示更少',
  Show_More: '顯示更多',
  Confirm: '確定',
  Unit: '單位',
  Back_To_Homepage: '回到首頁',
  Not_Now: '先不要',
  Please_Select: '請選擇',
  Continue: '繼續',
  NextStep: '下一步',
  Scan_Again: '重新掃描',
  Send: '送出',
  Logout: '登出',
  Please_Note: '請注意',
  Loading_Msg: '資訊處理中，請稍後...',
  Got_It: '我知道了'
};

// Placeholder english dictionary.
export const en = {
  ...zh,
  About: 'About',
  Terms_of_Service: 'Terms of Service',
  Privacy_Policy: 'Privacy Policy',
  WEB_QA:'FAQ',

  //Special
  FG_FANSILAND_INFO: 'You\'ll receive an e-ticket redeem email within 3-5 business days.',

  //票卷頁面
  FGT_Select_Show: 'Please select the event',
  FGT_Show_List: 'Events',
  FGT_Event_Info: 'Event details',
  FGT_Pick_Section: 'Select tickets',
  FGT_Sale_End: 'close at',
  FGT_Sale_Start_At: 'Sales begin',
  FGT_Not_Sale_Yet: 'Coming soon',
  FGT_Add_Calendar:'+ Calendar',
  FGT_Section_Usage: 'Valid dates',
  FGT_Section_Info: 'Ticket details',
  FGT_Ticket_Purchased: 'You have purchased {0} tickets.',
  FGT_Ticket_Available: 'You can buy {0} more tickets.',
  FGT_Show_Purchased_Max: 'Maximum tickets per event reached.',
  FGT_Purchased_Max: 'Purchase limit reached.',
  FGT_Sale_Start: 'The sales will start at',
  FGT_Back_To_Event_Page:'Back',
  FGT_Purchase_Now: 'Buy now',
  FGT_Uncomplete_Order:'Unfinished order. Continue?',
  FGT_Purchase_Limit_Info:'Purchase limit {0} tickets per event.',  
  FGT_SEC_Tickets_Remaion:'（{0} tickets left!）',

  //payment page
  "FGRD-ERR-11": 'Invalid code. Please re-enter.',
  "FGRD-ERR-12": 'Code has been used.',
  "FGRD-ERR-13": 'Code cannot be used for this event.',
  "FGRD-ERR-14": 'Code expired.',
  "FGRD-ERR-15": 'Usage limit exceeded.',
  "FGRD-ERR-16": 'Ahh you\'re too late, coupon code has been fully redeemed.',
  "FGRD-ERR-21": 'Something\'s wrong. Please try again.',
  FGPP_Handling: 'Fee',
  FGPP_Line_Pay: 'Mobile pay. ',
  FGPP_Aftee: ' ',
  FGPP_Aftee_Pay: 'Store/ATM/transfer. ',
  FGPP_Paypal_Pay:'International payment. ',
  FGPP_Cash:'Pay on-site',
  FGPP_Cash_Pay:'Cash payment. ',
  FGPP_CreditCard:'Credit Card',
  FGPP_CreditCard_Pay:'Pay by credit card.',
  FGPP_Crypto_Pay: 'Crypto payment. ',
  FGPP_Payment_Completed: 'Payment completed.',
  FGPP_Ticket:'Checkout',
  FGPP_Free: 'Free tickets',
  FGPP_Payment_Method: 'Payment',
  FGPP_Payment_Warning: 'Order\'s on hold. Please complete the purchase within 15 minutes to avoid cancellation.',
  FGPP_Checkout_Warning: 'No changes after checkout.',
  FGPP_Billing_Detail: 'Amount details',
  FGPP_Third_Party_Handling:'Third-party transaction fee',
  FGPP_Notice: 'Notice',
  FGPP_Note_1: 'Please confirm your order details and prices. No ticket exchanges available.',
  FGPP_Note_2: 'If you purchase multiple tickets, please enter with your companions to validate them.',
  FGPP_Note_3: 'This system uses QR Code e-tickets. The QR Code will be displayed 24 hours before the event starts.',
  FGPP_Note_4: 'Refund Period: Within 3 days of purchase. If less than 3 days to show, refund deadline is 2 hours before performance.',
  FGPP_Note_5: 'Refund policy: ',
  FGPP_Note_6: 'Line Pay: Full refund.',
  FGPP_Note_7: 'AFTEE: Full refund.',
  FGPP_Note_8: 'PayPal: Full ticket refund, excluding a 8% transaction fee.',
  FGPP_Note_9: 'USDT(Polygon): 4% refund fee will be charged.',
  FGPP_Note_10: 'Cash payment: Non-refundable.',
  FGPP_Note_11: 'Credit Card: 4% refund fee will be charged.',
  FGPP_Refund_Note: 'Refund Policy',
  FGPP_Confirm_Order_Info: 'Order Details',
  FGPP_Total_Amount: 'Total Amount',
  FGPP_Next_Step_Warning: 'By clicking "Next", you have reviewed and agreed',
  FGPP_Pre_Step: 'Back',
  FGPP_Enter_Checkout: 'Checkout',
  FGPP_Payment_Refund_Warning: 'Please be aware of refund valid time and fees may not be refunded.',
  FGPP_QRcode_Warning: 'Please show the QR code to satff for scanning',
  FGPP_Cash_Confirm: 'Check Result',
  FGPP_Cash_Confirm_Warning: 'After the payment is made, click "Check Result".',
  FGPP_Payment_Uncomplete: 'Payment pending',
  FGPP_Payment_Method_Error: 'Payment Error',
  FGPR_Aftee_Warning: `Order confirmed. Please pay within AFTEE's deadline to avoid late fees.`,
  FGPP_Order_Expired:'Order overdue.Please try again.',
  FGPP_Start_Checkout: 'Checkout',
  FGPP_NextStep_Warning: `Click Checkout to confirm \nyour order info`,

  //payment result
  FGPR_Payment_Complete: 'Order Complete!',
  FGPR_Payment_Complete_Semi: 'Order status subject to transaction history.',
  FGPR_Crypto_Warning: 'Please note that the payment will only be completed upon blockchain verification.',
  FGPR_Ticket_Confirm:'Check "Tickets"',
  FGPR_Trans_Fail:'Transaction failed.Try again.',

  //票夾頁面
  FGC_Ticket: 'Ticket',
  FGC_Ticket_No: 'Ticket No.',
  FGC_Original_Price: 'Original price',
  FGC_Refund: 'Refund',
  FGC_Confirm_Refund:'Confirm',
  FGC_Refund_Fail:'Failed',
  FGC_Not_Refundable:'Period expired',
  FGC_Not_Aavailable: 'Not available',
  FGC_Event_Ended: 'Event ended',
  FGC_Redeemed: 'Redeemed',
  FGC_Not_Redeem: 'Unredeemed',
  FGC_Barcode_Notice1: 'Disable dark mode. Use white background. Ensure high contrast.',
  // FGC_Barcode_Notice2:'Adjust your screen brightness.',
  FGC_Barcode_Notice3: 'QR Code will be available 24 hours before the event.',
  FGC_Owner: 'Name',
  FGC_My_Transactions:'Order History',
  FGC_Zero_Ticket_Notice1: "Don't have tickets yet.",
  FGC_Zero_Ticket_Notice2:'Tickets will be displayed here.',
  FGC_Ticket_Tax_No: 'Compliance No.',

  //折扣頁面
  FGD_Discount: 'Discount',
  FGD_Discount_Expired: 'Discount Expired',
  FGD_Expire_On: 'Expire on',
  FGD_Coupon_Code:'Coupon Code ',
  FGD_Coupon_Warning: 'Please note that only one coupon code available per order.',
  FGD_Zero_Coupon: 'No coupons available.',
  FGD_Zero_Coupon_Info: 'Stay tuned for benefits updates; we will notify you promptly.',

  //足跡頁面
  FGP_Badge_Info: 'Badge info',
  FGP_Claimed_Date: 'Collected date',
  FGP_Unclaim: 'Not collected',
  FGP_Event_Holder: 'Organizer',
  FGP_Badge_Collection: 'Badge Collection',
  FGP_My_Footprints:'Footprints',
  FGP_No_Footprints:'Currently no footprints.',
  FGP_My_Footprints_Note:'Your activity footprints are displayed here.',

  //個人資訊
  FGU_Edit_Profile: 'Edit profile',
  FGU_Display_Name: 'Display name',
  FGU_Real_Name: 'Legal name',
  FGU_Explorer: 'Explorer',
  FGU_Footprint: 'Footprint',
  FGU_Ticket: 'Ticket',
  FGU_Coupon_List:'Coupon',
  FGU_Personal_Info: 'User info',
  FGU_Name_Warning: 'Please enter your legal name for entry verification.',
  FGU_Gender:'Gender',
  FGU_Birthday:'Birthday',
  FGU_Member_Card:'My Pass',
  FGU_Member_Card_Title:'Privilege Pass',
  FGU_Member_Card_Fill:'Fill out below',
  FGU_Member_General:'General member',
  FGU_Member_Unapproved:'Not approved yet.',
  FGU_Member_Activate:'Activate',
  FGU_Member_Date:'Activate date',
  FGU_Member_Point:'會員集點',
  FGU_Member_Point_Warning:'請再次確認集點金額',
  FGU_Member_Pos_Amount:'消費金額',
  FGU_Member_Neg_Amount:'沖銷金額',
  FGU_Member_Point_Pos:'切換回正數',
  FGU_Member_Point_Neg:'切換成負數',

  //交易頁面
  FGTR_Transaction_Fail:'Failed',
  FGTR_Transaction_Completed:'Completed',
  FGTR_Wait_Confirm:'Confirming',
  FGTR_Wait_Payment:'Pending',
  FGTR_Cancel_Order:'Cancelled',
  FGTR_Refunded: 'Refunded',
  FGTR_Show: 'Events',
  FGTR_Section: 'Tickets',
  FGTR_Order_Id: 'Order ID',
  FGTR_Amount: 'Amount',
  FGTR_Order_Date: 'Order date',
  FGTR_My_Transaction:'Order History',
  FGTR_Zero_Order1: 'No order history.',
  FGTR_Zero_Order2: 'Oreders history will be displayed here.',
  FGTR_Payment_Method: 'Select payment method',
  FGTR_My_Ticket:'Tickets',
  FGTR_Ticket_Note:'At your fingertips.',

  //Event Info
  FGEI_Organizer:'Organizer',
  FGEI_Ranking:'Ranking',
  FGEI_Ur_Rank:'Your Rank',
  FGEI_Leaderboard:'Leaderboard',
  FGEI_Leaderboard_Notes:`The leaderboard follows the organizer's event rules.`,
  FGEI_Score:'My Score',
  FGEI_Score_Record:'Score Record',
  FGEI_Persional_Score_Record:'Score Record',
  FGEI_Introduction:'Event Info',
  FGEI_Event_Score:'Event Score',
  FGEI_Event_Score_Fixed: 'Offset Score',
  FGEI_Score_Notes:`The Event Score follows the organizer's event rules.`,
  FGEI_Badges:'Badges',
  FGEI_Badges_Notes:'Badges acquisition follows event rules.',
  FGEI_No_Event:'No badges have been generated yet.',
  FGEI_No_Event_Notes:'Stay tuned for immediate task notifications.',
  FGEI_Available:'Available',
  FGEI_Obtained:'Obtained',
  FGEI_Unobtained:'Unobtained',
  FGEI_Event_Info:'Badge Info',
  FGEI_Share_Now:'Share Now',
  FGEI_Badge_Notes:`Badge usage follows follows the organizer's event rules.`,
  FGEI_Share_Event:'Share Now',
  FGEI_Share_To:'Share to',
  FGEI_Copy_Link:'Copy',
  FGEI_Share_Notes: 'Fan power: Share tickets,pack the house!',

  //Home
  HP_EVENT_RUNNING:'Ongoing',
  HP_EVENT_ENDED:'Ended',
  HP_EVENT_RECENT:'Upcoming',
  HP_EVENT_RECENT_SUBTITLE: `Don't miss any you may like.`,
  HP_EVENT_PASSED:'Past',
  HP_EVENT_PASSED_SUBTITLE:'Your footprints earn you perks.',
  HP_CHK_EVENT:'Badge Event',

  //footer
  FOOTER_PWA_INSTALL: 'Create a FANSI GO shortcut for easy access!',
  FOOTER_PWA_IOS: 'Create a FANSI GO shortcut on Safari.',
  FOOTER_PWA_IOS_INFO1: 'Click the button below,',
  FOOTER_PWA_IOS_INFO2: 'select "Add to main screen"',
  FOOTER_PWA_Create_ShortCut:'Shortcut',
  FOOTER_HP:'Home',
  FOOTER_Collection:'Collection',

  //System
  SYS_CHK_NOW:'Check now',
  SYS_Quest_notice:'You have {0} new quests!',

  //通用
  Close: 'Close',
  Cancel: 'Cancel',
  Cancel_Order:'Cancel',
  Cancel_Order_Confirm:'Confirm',
  Close_Window: 'Close window',
  Copied: 'Copied',
  detail_info: 'Detail info',
  Edit: 'Edit',
  Expired: 'Expired',
  Order_Id: 'Order ID',
  Purchased_Date: 'Purchased date',
  Redeem:'Check',
  Save: 'Save',
  more:'More',
  Sold_Out: 'Sold out',
  Ticket_Due: 'Expiration date',
  Input:'Enter ',
  Show_Less: 'Show less',
  Show_More: 'Show more',
  Confirm: 'Confirm',
  Unit:'Currency',
  Back_To_Homepage: 'Home',
  Not_Now: 'Not now',
  Please_Select:'Select',
  Continue: 'Continue',
  NextStep: 'Next',
  Scan_Again:'Scan Again',
  Send:'Send',
  Please_Note:'Note',
  Logout: 'Logout',
};
